.intro_component h1{
    font-size: 2.5rem;
}

.intro_component h4{
    width: 75%;
}

.industrie_page-title img{
    width: 15%;
}

.industrie_page-title h1{
    color: #28367d!important;
}
