.card_sections {
  height: 43rem;
  width: 30%;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.section_banner {
  height: 15rem;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: none;
  border-bottom-right-radius: none;
}

.section_text-content {
  background-color: #e4e4e4;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 62vh;
  position: relative;
  transition: transform 0.4s ease-in-out;
}

/* .section_text-content h4 {
    position: absolute;
    top: 35%;
    font-size: 2rem;
    text-align: center;
    transition: font-size 0.4s ease-in-out;
}

.section_text-content p {
    transform: translateY(100%);
    opacity: 1;
    transition: transform 0.1s ease-in-out, opacity 0.3s ease-in-out;
} */

.card_sections {
  box-shadow: none;
  transform: translateY(0);
  transition: all 0.4s ease-in;
}

.card_sections:hover {
  box-shadow: 3px 3px 8px rgba(255, 255, 255, 0.4);
  transform: translateY(-10px);
  transition: all 0.4s ease-in;
}

.card_sections .section_text-content h4 {
  padding: 0 !important;
  top: 5% !important;
  text-align: start;
  font-size: 1.5rem;
}

.card_sections .section_text-content p {
  position: absolute !important;
  top: 15%;
  transform: translateY(0);
  opacity: 1;
}

.industry_bigcontainer {
  padding: 3rem;
}

.card_section-container {
  padding: 0 3rem;
}
