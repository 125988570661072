.vcms-background {
    background-image: url('../../img/VCMS/carousel.png');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 750px;
  }
  
  .vcms-title {
    width: 90%;
  }
  
  .vcms-title h1 {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    width: 35%;
  }
  
  .vcms-heading {
    color: #25213C;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 1.4;
    margin-bottom: 1rem;
  }
  
  .vcms-paragraph {
    color: #000000;
    line-height: 1.8;
    text-align: justify;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }
  
  .vcms-list {
    color: #000000;
    line-height: 1.8;
    text-align: justify;
    list-style-type: disc;
    margin-left: 20px;
    font-size: 1.1rem;
  }
  
  .vcms-image-box {
    width: 30%;
    height: 500px;
    background-size: cover;
    background-position: center;
  }
  
  .vcms-image-box1 {
  width: 100%;
    height: 500px;
    background-size: cover;
    background-position: center;
  }
  
  .vcms-info-box {
    width: 30%;
    background-color: #00134B;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
  }
  
  .vcms-info-box h1 {
    color: white;
    font-size: 45px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }
  
  .center-div {
    display: flex;
    justify-content: center;
    align-items: center;  
    text-align: center;  
    margin: 3rem 0;
  }
  
  .vcms-heading {
    margin: 0;  
    padding: 0;  
  }
  .vcms-image{
    object-fit: cover;
  }
  .flex-column {
    flex-direction: column !important;
    padding: 20px;
    font-size: 28px;
}