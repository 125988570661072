.geospatial__banner{
  height: 421px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #fff;
}

.geospatial__banner .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background: rgba(0,0,0,0.2);
}

.geospatial__banner h1, .geospatial__banner h3{
  text-shadow: 2px 2px #000;
}

.rotate_keyframe{
  /* transition: rotate 0.3s ease-in-out; */
  animation-name: rotate;
  animation-duration: 30s;
  animation-iteration-count: infinite;
}

 #circle_wheel img:hover .rotate_keyframe,  .rotate_keyframe:hover{
  -webkit-animation-play-state:paused;
  -moz-animation-play-state:paused;
  -o-animation-play-state:paused;
  animation-play-state:paused;
}

@keyframes rotate {
  0%   {transform: rotate(0deg);}
  12.5%  {transform: rotate(-45deg);}
  25%  {transform: rotate(-90deg);}
  37.5%  {transform: rotate(-135deg);}
  50%  {transform: rotate(-180deg);}
  62.5%  {transform: rotate(-225deg);}
  75%  {transform: rotate(-270deg);}
  87.5%  {transform: rotate(-315deg);}
  100% {transform: rotate(-360deg);}
}

.geospatial__paralax{
  min-height: 25rem;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.shortcut__bg{
  position: relative;
  /* border-radius: 10px; */
  background-position: center center;
  height: 192px;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1);
  transition: all 0.3s ease-in-out;

}
.shortcut__bg .overlay__bg{
  /* border-radius: 10px; */
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.4);
  z-index: -1;
  transition: all 0.4s ease-in-out;

}
.shortcut__bg:hover .overlay__bg{
  background: rgba(0,0,0,0.6);
  transition: all 0.4s ease-in-out;
}


.shortcut__bg:hover{
  transform: scale(1.05);
  box-shadow: 0px 0px 15px #05345d;
  border: 1px solid #fff;
  transition: all 0.3s ease-in-out;
}
.overlay__bg h4{
  position: absolute;
}

.counter_title {
  padding-top: 3rem;
  text-align: center;
  color: #001d6e;
}
.counter_title h2 {
  color: #001d6e;
  font-weight: 300;
}
.counter_title h2 span {
  font-weight: 600;
}
.counter_title .container .row .inner_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.counter_title .container .row .inner_container .counter_content {
  width: 50%;
  height: 100%;
  padding-top: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.counter_title .container .row .inner_container .counter_content .icon-img {
  width: 60px !important;
  height: 60px !important;
  border-radius: 0 !important;
}
.counter_title .container .row .inner_container .counter_content .number {
  font-size: 45px !important;
  padding: 0;
  font-weight: bold;
  color: #001d6e !important;
}
.counter_title .container .row .inner_container .counter_content span {
  color: #001d6e !important;
  height: 100%;
  font-size: 45px;
  font-weight: bold;
  margin-right: 0 !important;
}
.counter_title .container .row .inner_container .counter_content .year {
  color: #051c2c !important;
  font-weight: bold;
  font-size: 25px;
}
.counter_title .container .row .inner_container .counter_content .counter-desc {
  color: #000 !important;
  font-size: 20px;
  margin: 0;
  padding: 0 0 10px 10px;
}

.list-disk{
  list-style: disc;
}

.cases__paralax{
  height: 30rem;
  width: 100%;
  background-size: cover;
  background-position: center center;
}

.service__img{
  height: 192px;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.service__img .overlay__bg{
  position: absolute;
  height: 192px;
  width: 100%;
  left: 0;
  background: rgba(0,0,0,0.4);
}

.service__img:hover{
  transform: translateX(5px) translateY(-5px);
}

.service__img:hover .overlay__bg:hover{
  position: absolute;
  height: 192px;
  width: 100%;
  left: 0;
  background: rgba(0,0,0,0.6);
}

.service__content{
  position: relative;
  z-index: 2;
}

.retail__banner {
  height: 421px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #fff;
}