.counter_title{
    padding-top: 3rem;
    text-align: center;
    color: #121848;
}

.counter_title h2{
color: #121848 !important;
font-weight: 300;
}

h2 span{
    font-weight: 600;
}

.inner_container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.counter_content{
    width: 25%;
    height: 100%;
    padding-top: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    .icon-img{
        width: 75px!important;
        height: 75px!important;
        border-radius: 0!important;
    }

    .number{
        font-size: 50px!important;
        padding: 0;
        font-weight: bold;
        color: #121848 !important;
    }

    .counter_content span{
        color: #121848 !important;
        height: 100%;
        font-size: 45px;
        font-weight: bold;
        margin-right: 0!important;
    }
    .year{
        color: #051c2c !important;
        font-weight: bold;
        font-size:25px
    }
    .counter-desc{
        color: #121848 !important;
        font-size: 25px;
        margin: 0;
        padding: 0 0 10px 10px;
    }