.fatf__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fatf_overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 500px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
