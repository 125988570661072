.career_content {
    position: absolute;
    right: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.hr_content_1,
.hr_content_2,
.hr_content_3 {
    text-align: right;
    margin-top: 10px;
}

.hr_content_1 .hr,
.hr_content_2 .hr,
.hr_content_3 .hr {
    text-shadow: 5px 5px 7px #08394a;
    color: #fff;
    font-size: 45px;
}

@media (max-width: 768px) {
    .hr_content_1 .hr,
    .hr_content_2 .hr,
    .hr_content_3 .hr {
        font-size: 15px;
    }
}
