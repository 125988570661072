.service_img {
  height: 25rem;
  width: 50%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.service-text_content {
  align-items: start;
}
