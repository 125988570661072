@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

$primary-color : #051c2c;
$primary-color-light : #001d6e;
$sec-color : #f1f1f1;
$primary-font: 'Poppins', sans-serif;
$old-font: 'Calibry', sans-serif;

body{
 font-family: $primary-font!important;
 color: $primary-color!important;
 overflow-x: hidden;
 width: 100vw;
    .gap-8{
        gap: 8rem
    }
    .w-18{
        width: 18%;
    }
    .w-23{
        width: 23%;
    }
    .w-34{
        width: 34%;
    }
    .w-40{
        width: 40%;
    }
    .w-80{
        width: 80%;
    }
    .w-30 {
        width: 30%;
    }
        // .w-50{
        //     width: 45%;
        // }
    .app{
        display: flex;
        justify-content: center;

        // .container{
        //     width: 1024px;
        // }
    }
        //NAVBAR
        .navbar{
            box-shadow: 2px 10px 10px rgba(0,0,0,0.05);
            background-color: rgba(0,0,0,0.2);
            position: fixed;
            z-index: 6;
            height: 5rem;
            width: 100vw;
            top: 0;
            box-shadow: 10px 10px 10px rgba(255,255,255,0.1);

                &.scrolled{
                    background: $primary-color;
                    transition: all 0.2s ease-in;    
                }

            .container_content{
                padding: 0 20px;
                display: flex;
                align-items: center;
                gap: 20px;
                height: 8vh;
                width: 100%;
    
                
                .left_links{
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    height: 10vh; 
                    position: relative;

                    
                      
                    #mobile_view{
                        display: none;
                    }

                    .mobile_menu{
                        font-size: 1.6rem;
                        font-weight: bold;
                        color: #fff;
                    }

                    .logo{
                        img{
                            width: 10rem; 
                            height: auto;
                            margin-bottom: 0.5rem;
                        }
                    }
                    .links{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        gap: 1.5rem;
                        

                        .link{
                            font-size: 1.15rem;
                            color: #fff;
                            text-decoration: none;
                            display: inline-block;
                            position: relative;

                            &:hover {
                                &::after{
                                    width: 100%;
                                    background: #fff; 
                                }
                            }

                            &::after{
                                content: "";
                                display: block;
                                height: 3px;
                                width: 0;
                                top: 5px;
                                background: transparent;
                                transition: all 0.3s;
                            }
                                    
                             h6{
                                margin: 0!important;
                             }
                        }

                        #open_menu{
                            h6{
                                text-decoration: none;
                                color: #fff;
                            }
                            &:hover{
                                .submenu{
                                    display: flex;
                                }
                            }
                        }

                        #open_hrMenu{
                            &:hover{
                                #subHrMenu{
                                    position: absolute;
                                    left: 65%;
                                    top: 36.5%;
                                    display: flex;
                                    flex-direction: column;
                                }
                            }
                            #subHrMenu{
                                display: none;
                            }
                        }

                        .submenu{
                            width: max-content;
                            display: none;
                            list-style: none;
                            position: absolute;
                            top: 1.5rem;
                            left: -1.15vw;
                            padding: 15px;
                            flex-direction: column;
                            z-index : 1;

                            a{
                                text-decoration: none;
                                color: #051c2c;
                                font-size: 14px;
                                li{
                                    background: #dedede;
                                    border-bottom: 1px solid lightgray;
                                    color: #777;
                                    padding: 0.8em 1em;
                                    transition: 0.3s all linear;
                                    display: block;
                                    // line-height: 0%;
                                
                                    &:hover{
                                        background: $primary-color;
                                        color: #fff;

                                    }

                                }
                                
                            }
                            li{
                                &#open_hrMenu{
                                    background: #dedede;
                                    border-bottom: 1px solid lightgray;
                                    color: #777;
                                    padding: 0.8em 1em;
                                    transition: 0.3s all linear;
                                    display: block;
                                    font-size: 14px;
                                
                                    &:hover{
                                        background: $primary-color;
                                        color: #fff;

                                    }

                                }
                                    
                            }
                        }

                        .link{
                            text-decoration: none;
                            color: #fff;
                            cursor: pointer;

                            h6{
                                font-size: 16px;
                                font-weight: 500;
                                display: inline-block;
                                position: relative;
                            }
                        }
                    }
                }
                
                .side_links{
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    margin-left: auto;
                    cursor: pointer;

                    span{
                        color: #fff;
                    }
                    .carousel_items{
                        &:hover{
                            color: #c9c9c9;
                        }
                    }
                }
           }
        }
        .slick-prev {
            color: #000!important;
        }
        .slick-next{
            color: #000!important;
        }
        #menu_img{
            display: none;
            position: absolute;
            height: auto;
            z-index: 6;
            background: #fff;
            right: 1%;
            top: 11%;padding: 20px;
            width: 500px;

            .goTo{
                img{
                    height: 8rem;
                    width: 100%;
                }
            }
        }
        

    .slick-dots {
        display: flex;
        position: absolute;
        transform: translateY(-25%);
        top: 90%;
        cursor: pointer;
        left: calc(14vw - 610px) !important;
        gap: 0.5rem;

            li{
                &.slick-active{
                    font-size: 18px;
                    opacity: 1!important;
                    margin: 0 0.5rem;
                    button{
                        &::before {
                            content: ''!important;
                        }
                    }
                }
            }

            li{
                button{
                    &::before {
                        font-size: 18px!important;
                        color: #fff;
                        opacity: 0.3;
                    }
                }
            } 

        .slick-active{
            display: inline-block;
            width: 15px;
            height: 15px;
            margin: 0;
            opacity: 0.3;
            background: #fff;
            border-radius: 50%;
        }
    }
    .content {
        .video_section{
            width: 100vw;
            position: absolute;
            top: 21%;
            left: 61%;
            height: 844px;
        }
        .video_section1{
            width: 100vw;
            position: absolute;
            // top: 21%;
            // left: 61%;
            height: 844px;
        }
        .text_content{
            position: absolute;
            color: rgb(255, 255, 255);
            // top: 25%;
            margin:0 5rem;
            
            .btn-show{
                background: transparent;
                border: 1px solid #fff;
                color: #fff;
                transition: all 0.3s ease-in-out;
                font-weight: 500;
                position: absolute;
                z-index: 10;

                &:hover{
                    background: #fff;
                    border: 1px solid #fff;
                    color: $primary-color;
                    transition: all 0.3s ease-in-out;
                }
            }
            .btn-show1{
                background: rgba(0,0,0,0.5);
                border: 1px solid #fff;
                color: #fff;
                transition: all 0.3s ease-in-out;
                font-weight: 500;
                position: absolute;
                z-index: 10;

                &:hover{
                    background: #fff;
                    border: 1px solid #fff;
                    color: $primary-color;
                    transition: all 0.3s ease-in-out;
                }
            }

            h1{
                font-size: 3rem;
                // line-height: 3.5rem;
                margin-bottom: 2rem;
                font-weight: bold;
                
                // @media (max-width:768px) {
                //     font-size: 5rem!important;
                // }
            }
            p{
                font-size: 2.5vw;
                // line-height: 2.2rem;
            }
        }
        .carousel_video{
            height: 950px;
        }
        .img_content{
            position: relative;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;

            .overlay_bg{
                position: absolute;
                clip-path: polygon(0 0, 70% 0, 50% 100%, 0% 100%);
                background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 25%, transparent 60%);
                height: 100%;
            }

            img{
                width: 100%;
            }
        }
    }
    

    //Introduction
    .ReactModal__Overlay{
        z-index: 10;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .intro_container{
        .video_player-container{
            img{
                height: 20rem;
            }
            position: relative;

            &:hover{
                .overlay__bg1{
                    background: rgba(0,0,0,0.7);
                    transition: all 0.4s ease-in-out;
                }
            }

            .overlay__bg1{
                position: absolute;
                top: 0;
                height: 20rem;
                width: 100%;
                background: rgba(0,0,0,0.4);
                transition: all 0.4s ease-in-out;

                &:hover {
                    .play__btn{
                        opacity: 1;
                        transition: all 0.4s ease-in-out;
                    }
                }

                .play__btn{
                    font-size: 7rem;
                    position: absolute;
                    top: 37%;
                    right: 40%;
                    opacity: 0.3;
                    cursor: pointer;
                    transition: all 0.4s ease-in-out;
                }
            }

        }
    }
    .intro__img{
        width: 100%;
        height: 42vh;
    }

    .content__title{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        padding: 0 1.5rem;
        
        h2{
            align-items: center;
            width: 90%;
            color: $primary-color-light;
            line-height: 1.3;
            // margin-bottom: 1rem;
            font-weight: 400;

            img{
                width: 50%;
                margin:0 0.5rem 0.5rem 0;
            }
        }
        p{
            text-align: justify;
            font-size: 13pt;
            line-height: 1.3;
        }
        .button_div{
            .btn-show-sec{
                background: #001d6e;
                border: 1px solid #001d6e;
                color: #fff;
                transition: all 0.3s ease-in-out;
    
                &:hover{
                    background: transparent;
                    border: 1px solid #001d6e;
                    color: #001d6e;
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }

    //Global Compliance
    .compliance_section{
        background-color: $sec-color;
        padding-top: 3rem;
        .compliance__title{
            h2{
                color: $primary-color-light;
                font-weight: 300;
    
                span{
                    font-weight: 600;
                }
            }
        }
    }

    //Lines Of Business
    .title_business{
        padding-top: 3rem;
        text-align: center;
        color: $primary-color-light;

        .container {

            .row{
                
                h2{
                    font-weight: 300;
                }
                span{
                    font-weight: 600;

                }

                p{
                    font-size:26px;
                    color:#666;
                }
                .business__card-container{
                    border-radius: 20px;
                    border: 1px solid rgba(0,0,0,0.15);
                    overflow: hidden;
                    padding: 10px;
                    height: 35vw;
                    transition: transform .2s;

                    &:hover{
                        transform: scale(1.01);
                        box-shadow: 0px 0px 10px #999;
                    }
                    a{
                        text-decoration: none;
                        color: #001d6e;
                        font-weight: bold!important;
                    }
                    img{
                        height: 50%;
                        width: 100%;
                        border-radius: 20px;
                        margin-bottom: 1rem;
                    }

                    h4{
                        font-size: 1.2rem;
                    }

                    p{
                        font-size:14px;
                        text-align: center;
                        color: #4d4d4d;
                    }
                }
                
            }
        }
    }
    //Services
    .services_section{
        text-align: center;
        padding: 3rem 0;

        .services__title{
            margin-bottom: 3rem;

            h2{
                color: $primary-color-light;
                font-weight: 300;
    
                span{
                    font-weight: 600;
                }
            }
        }
        .col-md-4{
            .img_bg{
                position: relative; 
                height: 192px;
                background-position: center center;
                background-size: cover;
                transition: all 0.4s ease-in-out;


                &:hover {
                    transform: scale(1.05);
                    box-shadow: 10px 10px 5px rgba(0,0,0,0.2);
                    border: 1px solid #fff;
                    transition: all 0.4s ease-in-out;


                    .overlay__bg{
                        background: rgba(0,0,0,0.7);
                        transition: all 0.4s ease-in-out;
                    }
                }

                .overlay__bg{
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background: rgba(0,0,0,0.4);
                    z-index: 0;
                    transition: all 0.4s ease-in-out;

                }
                .services__content{
                    transform: scale(1)!important;
                    color: #fff;
                    height: 100%;
                    position: absolute;
                    text-align: start;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;

                    h4{
                        margin-bottom: 1rem;
                        font-size: 22px;
                    }
                    p{
                      font-weight: 300;  
                      font-size: 14px;
                    }
                }
            }
    
        }
    }
    .services_section1{
        text-align: center;
        padding: 3rem 0;

        .services__title{
            margin-bottom: 3rem;

            h2{
                color: $primary-color-light;
                font-weight: 300;
    
                span{
                    font-weight: 600;
                }
            }
        }
        .col-md-6{
            .img_bg{
                position: relative; 
                height: 300px;
                background-position: center center;
                background-size: cover;
                transition: all 0.4s ease-in-out;


                &:hover {
                    transform: scale(1.05);
                    box-shadow: 10px 10px 5px rgba(0,0,0,0.2);
                    border: 1px solid #fff;
                    transition: all 0.4s ease-in-out;


                    .overlay__bg{
                        background: rgba(0,0,0,0.7);
                        transition: all 0.4s ease-in-out;
                    }
                }

                .overlay__bg{
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background: rgba(0,0,0,0.4);
                    z-index: 0;
                    transition: all 0.4s ease-in-out;

                }
                .services__content{
                    transform: scale(1)!important;
                    color: #fff;
                    height: 100%;
                    position: absolute;
                    text-align: center;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 40%;
                    }
                    h4{
                        margin-bottom: 1rem;
                        font-size: 22px;
                    }
                    p{
                      font-weight: 300;  
                      font-size: 14px;
                    }
                }
            }
    
        }

    }
    //counter
    .counter_title{
        // background-color: $sec-color;
        padding-top: 3rem;
        text-align: center;
        color: #001d6e;
        h2{
            color: $primary-color-light;
            font-weight: 300;

            span{
                font-weight: 600;
            }
        }
        .container{
            .row{
                .inner_container{

                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;

                    .counter_content{
                        width: 25%;
                        height: 100%;
                        padding-top: 50px;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .icon-img{
                            width: 60px!important;
                            height: 60px!important;
                            border-radius: 0!important;
                        }

                        .number{
                            font-size: 45px!important;
                            padding: 0;
                            font-weight: bold;
                            color: #001d6e !important;
                        }

                        span{
                            color: #001d6e !important;
                            height: 100%;
                            font-size: 45px;
                            font-weight: bold;
                            margin-right: 0!important;
                        }
                        .year{
                            color: #051c2c !important;
                            font-weight: bold;
                            font-size:25px
                        }
                        .counter-desc{
                            color: #000 !important;
                            font-size: 20px;
                            margin: 0;
                            padding: 0 0 10px 10px;
                        }
                    }
                }
            }
        }
    }
    // Media
    .media__container{
        background-color: $sec-color;
        .card {
            height: 27rem;
            border-radius: 10px;
            .card-header{
                padding: 0;
                img{
                    height: 150px;
                    width: 100%;
                    border-radius: 10px 10px 0 0;
                }
            }
            .card-body{
                a{
                    font-size: 14px;
                    bottom: 16px;
                    position: absolute;
                }
            }
        }

        .media__title{
            text-align: center;
    
            h2{
                color: #001d6e;
                font-weight: 300;
    
                span{
                    font-weight: 600;
                }
            }
        }
        .swiper{
            padding: 1.9rem;

            .swiper-button-prev{
                left: 0;
                transform: scale(0.8);
            }
            .swiper-button-next{
                left: 97.5%;
                transform: scale(0.8);
    
            }
            .swiper-slide{
                padding-bottom: 3rem;
            }
        }
    }

.clientPage{
    .card{
        height: 100%!important;
    }
}

    // Clients
    .clients__container{
        background-color: #f1f1f1;
        padding: 2rem;
        text-align: center;
        padding: 3rem 0;

        .btn-show{
            background: #001d6e;
            // border: 1px solid #001d6e;
            color: #fff;
            transition: all 0.3s ease-in-out;
            font-weight: 500;

            &:hover{
                background: transparent;
                border: 1px solid $primary-color;
                color: $primary-color;
                transition: all 0.3s ease-in-out;
            }
        }

        .clients__title{
            margin-bottom: 3rem;

            h2{
                color: #001d6e;
                font-weight: 300;
    
                span{
                    font-weight: 600;
                }
            }
        }

        .client_list{
            mix-blend-mode: multiply;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            
            img{
                // filter: grayscale(1);  
                transform: scale(1); 
                width: 60%;
                transition: all 0.2s ease-in-out;
                
                &:hover{
                    // filter: grayscale(0);
                    transform: scale(1.1); 
                    transition: all 0.2s ease-in-out;
                }
            }
        }
    }
    .contact_us{
        display: flex;
        align-items: center;
        gap: 0.5rem;
        position: fixed;
        bottom: 0;
        z-index: 1;
        color: #fff;
        right: 3%;
        padding: 5px 1.5rem;
        border-radius: 10px 10px 0 0;
        background: #051c2c;
        font-weight: 500;
        cursor: pointer;

    }
    .contact_popup{
        width: 20rem;
        position: fixed;
        bottom: 0;
        right: 3%;
        z-index: 11;
        border-radius: 10px 10px 0 0;
        box-shadow: 2px 3px 10px 2px rgba(139, 133, 133, 0.5);
        animation: scaleAnimation 1s;
        // transition: all 0.3s ease-in;
        background: #fff;

        @keyframes scaleAnimation {
            0% {
              height: 0;
            }
            100% {
              height: 38vw;
            }
          }

        .contact__container-form_head{
            .error_border{
                border: 1px solid red;
            }
            .err-msg{
                font-size: 14px;
                font-weight: 500;
                color: red;
            }
            .contact__container-form_title{
                background-color: $primary-color;
                padding: 10px;
                color: #fff;

                div{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                }
            }
        }
    }
}

//footer
.footer{
    background-color: #2c2c2c;
    .container{
        .row{
            .foot_content{
                .footer_search{
                    border-radius: 6px;
                    height: 57px;
                    overflow: hidden;
                    position: relative;
                    z-index: auto;
                    #search_input{
                        background-color: #666666 !important;
                        padding: 20px;
                        width:100%;
                        border: none!important;
                        color: #fff!important;
                        outline: none;
                    }
                }

                .sub_footer{
                    .footer_title{
                        font-weight: bold;
                        color: #fff!important;
                    }
                    ul.more-than-five {
                        -webkit-column-count: 2;
                        -moz-column-count: 2;
                        column-count: 2;
                        -webkit-column-gap: 40px;
                        -moz-column-gap: 40px;
                        column-gap: 40px; 
                        }
                    ul{
                        margin-top: 1rem!important;
                        margin-left:0!important;
                        padding:0!important;
                        li{
                            list-style-type: none;
                            margin-bottom: 0.5rem;
                            a{
                                font-weight: 300;
                                font-size:14px;
                                color: #fff;
                                text-decoration: none;
                            
                                &:hover{
                                    color: #fff;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
                
            }
        }
        .row{
            .footer_socials{
                .footer_socials-icon{
                    .social_icons{
                        color: white;
                        font-size: 25px;
                        width: 40px;
                        height: 40px;
                        border-radius: 10px;
                        cursor: pointer;
                        transition: all 0.3s ease-in-out;
                        &:hover{
                            background-color: #fff;
                            color:#262626;
                            transition: all 0.3s ease-in-out;
                        }
                    }
                }
            }
        }
    }
    .footer_copyright{
        margin: 0;
        background: #262626;
        .copyright_content{
            position: relative;
            bottom: 0;
            width: 96%;
            padding: 10px;
            margin: 0;
            left:30px;
            li{
                list-style-type: none;
                padding: 0 10px;
                a{
                    font-size: 12px;
                    color: #707070!important;
                    text-decoration: none;
                }
            }
        }
    }

}

// Responsive styles
@media (max-width: 768px) {
body{
    // .navbar{
    //     // width: 96vw;
    // }
    .contact_us{
        right: 5%!important;
    }
}
    .geo_container{
        height: 100vh;
        .geo_thumb{
            width: 96%!important;
        }
        .geo_content{
            .img_geo-content{
                height: 100vh;
                width: 100%;

                    .text_geo-content{
                        &.col-md-4{
                            width: 90%;
                        }
                    p{
                        font-size: 3vw;
                        line-height: 2rem;  
                    }
                    h1{
                        font-size: 20px;
                        padding: 0;
                        margin: 0;
                    }
                    h2{
                        padding: 0;
                        margin: 0;
                        font-size: 16px;
                        line-height: 2rem;
                    }
                }
            }

        }
    }

    .navigation-menu{
        ul {
            /* previous styles */
            display: none;
        }
        .fade_animation{
            display: none!important;
        }
    }
    .navigation-menu{
        &.expanded{
            ul {
                overflow-y: hidden;
                left: -1.2rem;
                position: absolute;
                background: rgba(0,0,0,0.85);
                list-style: none;
                width: 100vw;
                height: 150vh;
                margin: 0;
                top: 0;
                padding: 3rem;
                display: flex;
                flex-direction: column;
                text-align: center;
                align-items: center;
                gap: 2rem;
                li{
                    a{
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .navbar{
        #pc_view{
            display: none;
        }
        #mobile_view{
            display: block!important;
        }
        .left_links{
            gap: 2rem!important;

            .logo {
                img{
                    width: 25vw!important;
                }
            }

            svg{
                top: 30%;
            }
        }
    }

    .mySwiper {
      width: 100%;
      height: auto;
    }
// .dataRole_section{
    
// }
    .retail__content-all {
        .img_section{
            &:first-child{
                display: none;
            }
            &:nth-child(2){
                display: none;
            }
        }
        .intro_content{
            height: auto;
            flex-direction: column!important;
            .retail__box{
                h5{
                    font-size: 1.5rem;
                }
                p{
                    font-size: 1rem;
                }
            }
        }
    }
  }
  
  @media (max-width: 576px) {
    .img_content{
        height: 600px!important;

        .overlay_bg{
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%)!important;
            background: linear-gradient(to right, rgba(0, 0, 0, 0.25) 100%, transparent 100%)!important;
        }
    }
    .carousel_video{
        height: 600px!important;

        .video_section{
            left: 22%!important;
            height: 31rem!important;
        }
        .video_section1{
            // left: 22%!important;
            height: 31rem!important;
        }
    }
    .mySwiper {
        .swiper-button-next{
            left: 92.5%!important;
        }
      .swiper-wrapper {
        .swiper-slide {
          width: 100% !important;
        }
      }
    }
    body{
        .navbar{
            width: 97vw;

            .container_content{
                .left_links{
                    .logo {
                        img{
                            width: 28vw!important;
                        }
                    }
                }
            }
        }
        .content{
            .text_content{
                width: 75%;
                margin: 0;
                top: 20%;
                text-align: center;
                // left: 4rem!important;

                .slide10Font{
                    font-size: 1.6rem!important;
                }

                .gap-8{
                    span{
                        width: 75%;
                    }
                }

                h1{
                    font-size: 2rem;
                    line-height: 2.5rem;
                    margin-bottom: 2rem;
                }

                p{
                    font-size: 4.5vw;
                    line-height: 1.5rem;
                }

                .btn-show{
                    font-size: 14px;
                }
            }
            .img_content{
                height: 110vw;
            }
        }
        .mt-5{
            margin-top: 1rem!important;
        }
        .content__title{
            margin-top: 1rem;
        }
        .intro_container{
            .video_player-container{
                .intro__img{
                    height: 20rem;
                }
                .overlay__bg1{
                    height: 20rem;
                    .play__btn{
                        top: 35%;
                        right: 35%;
                    }
                }
            }
        }
        .compliance_section{
            .compliance__title{
                text-align: center;
            }
        }
        .title_business{
            .container{
                .row
                {
                    p{
                        font-size: 16px;
                    }
                }
            }
        }
        .clients__container{
            .client_list{
                grid-template-columns: repeat(2, 1fr)!important;
            }
        } 
        .counter_title{
            .container{
                .row{
                    .inner_container{
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .counter_content{
                            width: 100%;
                        }
                    }
                }
            }
        }
        .title_business{
            .container{
                .row{
                    .flex-display{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .business__card-container{
                            height: 90vw;
                            width: 85%;
                        }
                    }
                }
            }
        }
        .footer_copyright{
            .copyright_content{
                margin: 0;
                flex-direction: column!important;
                text-align: center;
                left: 0!important;
                max-width: 100vw;
            }
        }

    }
}
.banner_img{
    height: 25rem;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;  

    .banner_overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 25rem;
        background: rgba(0,0,0,0.4);
    }
    .banner_content{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1{
            font-size: 3.5em;
            line-height: 2.5rem;
        }

        p{
            margin-top: 1rem;
            font-size: 2rem;
            line-height: 2rem;
        }
    }
}

// .executive_section{
//     .executive_members{
//         display: grid;
//         // grid-template-columns: 1fr 1fr;
//         .grid_executive{
//             border: 1px solid rgba(0,0,0,0.1);
//             .executive_title{
//                 padding: 10px;
//                 background: #b4babf;
//                 color: #fff;
//             }
//             .executive_members-member{
//                 // border-radius: 50%;
//                 width: 15rem;
//                 height: 20rem;
//                 background-position: center center;
//                 border-right:1px solid rgba(0,0,0,0.2);
//                 background-repeat: no-repeat;
//                 background-size: cover; 
//             }
//         }
//     }
// }

.executive_section{
    .executive_members{
        .border_bottom {
            padding-bottom: 1rem;
        }
        a{
            padding-bottom: 1rem;
            color: $primary-color;
            text-decoration: none;
        }
        .executive_title{
            color: $primary-color;

        }
        .executive_members-member{
            position: relative;
            border-radius: 50%;
            width: 15rem;
            height: 15rem;
            background-position: center 0.2rem;
            background-color: #94c9ff;
            // border:1px solid rgba(0,0,0,0.2);
            background-repeat: no-repeat;
            background-size: cover; 
            cursor: pointer;

            .overlay_bg{
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: rgba(0,0,0,0);
                transition: all 0.3s ease-in-out;
            }
            a{
                border: none;
                color: #fff;
                .executive_members-member_link{
                    position: absolute;
                    top: 45%;
                    right: 40%;
                    opacity: 1;
                    transition: all 0.3s ease-in-out;
                }
            }

            &:hover{
                .executive_members-member_link{
                    opacity: 1;
                    transition: all 0.3s ease-in-out;
                }
                .overlay_bg{                
                    background-color: rgba(0,0,0,0.5);
                    transition: all 0.3s ease-in-out;
                }
            }

           

        }
    }
}
.empty_about {
    flex: 0.2;
}
.sidebar_about {
    background-color: #f9fafb;
    height: auto;
    // flex: 2;
    width: 26vw;
    border-right: 1px solid rgba(145, 158, 171, 0.24);
    padding-top: 10rem;


    ul{
        display: flex;
        gap: 0.2rem;
        flex-direction: column;
        list-style: none;
        padding: 0;

        li{
            cursor: pointer;
            color: #212B36;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;
            padding: 20px;
            font-weight: bold;
            
            &.active_page{
                background: rgba(145, 158, 171, 0.08);
            }
            
            svg{
                font-size: 1.5rem;
                color: #212B36;
            }

            &:hover{
                background: rgba(145, 158, 171, 0.08);
            }
        }
    }
}

.contact_section{
    .contact_section-img{
        background-repeat: no-repeat;
        height: 22rem;
        border-radius: 50%;
        width: 22rem;
        background-size: cover;
        background-position: -50rem 0rem;
    }
    .contact_section-lower{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        transition: all 0.5s ease-in-out;
        .contact_section-lower_card{
            padding: 1.5rem;
            background: #e3e6e7;
            text-align: left;
            color: #051c2c;
            transition: all 0.5s ease-in-out;
            &:hover{
                background: #051c2c;
                color: #fff!important;
                transform: translateY(-5px);
                transition: all 0.5s ease-in-out;
                h5 {
                    color: #fff;
                    transition: all 0.5s ease-in-out;
                }
            }
        }

    }
}

.contact_section-lower_card{
    &:hover {
        h5{
            svg{
                stroke: #fff!important;
            }
        }

    }
}

.ceo_content{
    h3{
        font-weight: 400;
    }
    .ceo_img{
        position: relative;
        border-radius: 50%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #94c9ff;
        height: 20rem;
        width: 70%;

        .overlay_bg{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: rgba(0,0,0,0);
            
            transition: all 0.3s ease-in-out;
        }
        a {
            border: none;
            color: #fff;

            .ceo_img-social{
                position: absolute;
                top: 45%;
                right: 40%;
                opacity: 1;
                transition: all 0.3s ease-in-out;
            }
        }
        &:hover{
            .ceo_img-social{
                opacity: 1;
                transition: all 0.3s ease-in-out;
            }
            .overlay_bg{                
                background-color: rgba(0,0,0,0.5);
                transition: all 0.3s ease-in-out;
            }
        }
    }
    .two-column{
        column-count: 2;
        gap: 2.5rem;
        padding: 2rem;

        p{
            text-align: justify;
            span{
                margin-bottom: 2rem;
            }
        }
    }
    .ceo_signature{
        padding-right: 2rem;
    }
}


.mission_container{
    .mission_img{
        height: 38rem;
        background-size: cover;
        background-position: center center;
        border-radius: 20px;
    }
    .spacing_out{
        margin-top: 1rem;
        line-height: 1.9em;
    }
}
.geo_container {
    height: 100vh;
    .geo_content {
        .text_geo-content{
            color: rgb(255, 255, 255);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 50%;
            z-index: 1;

            &::before{
                content: '';
                height: 11vh;
            }
            
            .btn-show{
                margin-top: 1rem;
                background: #fff;
                // border: 1px solid $primary-color;
                color: $primary-color;
                transition: all 0.3s ease-in-out;
                font-weight: 500;
                width: 20%;
                // position: absolute;
                z-index: 10;
    
                &:hover{
                    background: transparent;
                    border: 1px solid #fff;
                    color: #fff;
                    transition: all 0.3s ease-in-out;
                }
            }
    
            h1{
                font-size: 2.5rem;
                margin-bottom: 2rem;
            }
            p{
                font-size: 1.5vw;
                line-height: 2rem;
            }
        }
        
        .img_geo-content{
            height: 100vh;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            justify-content: center;
            text-align: center;
            position: relative;

            .text_geo-content{
                h1{
                    font-size: 4.5rem;
                    padding: 0;
                    margin: 0;
                }

            }
            .overlay_bg {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.5);
                z-index: 0;
            }
    
            img{
                width: 100%;
            }
        }
    }
    .geo_thumb{
        height: 90px;
        background-color: rgba(0, 1, 1, 0.5);
        z-index: 1;
        position: absolute;
        bottom: 0;
        width: 100%;

        .geo_thumb-box{
            height: 90px;
            width: 10rem;
            padding: 0 1rem;
            color: #a8b4be;
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            position: relative;

                &:hover{
                    .overlay_bg{
                        position: absolute;
                        height: 90px;
                        background: rgba(0,0,0,0.5);
                        width: 100%;
                        z-index: -1;
                        transition: all 0.3s;
                    }
                }
            svg{
                color: #fff;
            }

            &.active{
                background-color: #fff;
                color: $primary-color;
                box-shadow: 1rem 0.5rem 2rem rgba(0,0,0,0.1);

                svg{
                    color: $primary-color;
                }
            }
        }
    }
}

.section_seperator{
    height: 5rem;
}
.retail__box{
    height: auto;
    width: 100%;
    flex-direction: row;
    border-radius: 10px;
    background: #e3e6e7;
    color: #051c2c;
    border: none;
    transition: all 0.5s;
    cursor: pointer;

    &:hover{
        background: #051c2c;
        color: white;
        transition: all 0.5s;
        transform: translateY(-5px);
    }
    
    h5{
        font-size: 1.7vw;
        margin-bottom: 1.5rem;
    }
    p{
        font-size: 1.35vw;
    }
}
.footfall_section{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.footfall_section-sub{
    border-top: 1px solid;
    padding-top: 1rem;

    h5{
        height: 5rem;
    }
}

.footfall_section-sub_analytics{
    border-top: 1px solid;
    padding-top: 1rem;

    h5{
        height: 3rem;
    }
}

.dataRole_section{
    .dataRole_section-part_img{
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden
    }
    .dataRole_section-part_first{
        width: 40%;
    }
    .dataRole_section-part_last{
        width: 40%;
    }
    .seperator{
        width: 0.5rem;
        background-color: #8d8c8c;
    }
}

.loc_section{
    border-radius: 10px;
    transition: 0.5s all;
    // border: 1px solid $primary-color;

    &:hover{
        transform: translateY(-10px);
        box-shadow: rgba(0, 0, 0, 0.24) 8px 8px 3px;
        background-color: $primary-color!important;
        color: #fff;
        // border: 1px solid #fff;
        transition: 0.5s all;

        .loc_section-img{
            // border: 1px solid #fff;
            transform: scale(1.1);
            transition: 0.5s all;
        }
    }

    .loc_section-img{
        height: 10rem;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        transition: 0.5s all;

        // border-radius: 10px;
    }
}

.ecommerce_row{

    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    .ecommerce_section{
        border: 1px solid rgba(0,0,0,0.2);
        padding: 1rem;
    }
}
.banner_img-ai{
    height: 35rem;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;  

    .banner_overlay-ai{
        position: absolute;
        top: 0;
        width: 100%;
        height: 35rem;
        left: 0;
        background: rgba(0,0,0,0.2);
    }
    .banner_content-ai{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1{
            font-size: 3.5em;
            line-height: 2.5rem;
        }

        p{
            margin-top: 1rem;
            font-size: 2rem;
            line-height: 2rem;
        }
    }
}
@media (max-width: 768px){
    
.banner_img-ai{
    height: 100vh;
    .banner_overlay-ai{
        height: 100vh;
    }
        p{
            font-size: 1.5rem!important;
        }
    }
}


.banner_img-dto{
    height: 35rem;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;  

    .banner_overlay-dto{
        position: absolute;
        top: 0;
        width: 100%;
        height: 35rem;
        left: 0;
        background: rgba(0,0,0,0.5);
    }
    .banner_content-dto{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1{
            font-size: 3.5em;
            line-height: 2.5rem;
        }

        p{
            margin-top: 1rem;
            font-size: 2rem;
            line-height: 2rem;
        }
    }
}

.right_side {
    height: 10rem;
    .background_design{
        height: 25rem;
        width: 100%;    
        background: radial-gradient(circle, rgba(63,94,251,0.5) 0%, rgba(252,70,107,0.5) 100%);
        clip-path: polygon(0 0%, 28% 0%, 39% 100%, 10% 100%);
        position: absolute;
        top: 0;
    }
    .box_content{
        position: absolute;
        .box_ai{
            padding: 10px;
            border-radius: 10px;
            border: 1px solid rgba(255,255,255,0.3);
            background: radial-gradient(circle, rgba(63, 94, 251, 0.5) 0%, rgba(252, 70, 107, 0.5) 100%);
            // transition: all 0.3s;

            .check{
                font-weight: bold;
                background-color: #19b2dd;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 1px 1px #fff;
                font-size: 20px;
            }
        }
    }
}

.ai_box{
    height: auto;
    width: 100%;
    border-radius: 10px;
    background: #e3e6e7!important;
    color: #051c2c;
    border: none;
    transition: all 0.5s;
    cursor: pointer;

    &:hover{
        background: #051c2c!important;
        color: white;
        transition: all 0.5s;
        transform: translateY(-5px);
        
        h5 {
            color: white;
        }
    }
}

.retail_bg{
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.slideFirst{
    top: 25%;
}

.slideTeam{
    top: 15%;
}

.slidePos{
    top: 20%;
}

.slideGeoPos{
    top: 15%;
}

.slideAiPos{
    top: 13%;
}

.slideBigPos{
    top: 10%;
}

.slideMultiPos{
    top: 30%;
}

.slideEcoPos{
    top: 14%;
}

.img_content-banking{
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .overlay__bg{
        position: absolute;
        height: 15rem;
        width: 100%;
        background: rgba(0,0,0,0.3);
        bottom: 0;
        z-index: 1;

        .text_content{
            top: 0;
        }

        img{
            width: 100%;
        }

        h1{
            line-height: 2.5rem!important;
            margin-bottom: 1.5rem!important;
        }
        h2{
            font-size: 2.5rem;
        }
        h4{
            font-size: 2rem;
        }
        p{
            margin-bottom: 1rem;
        }
    }

}

.choice_content{
    h4{
        color: #136597;
        font-weight: bold;
    }
}

.dashboard_container{
    // height: 50rem;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: center center!important;
    position: relative;

    .container{
        position: absolute;
        max-width: 100%;
    }

    .btn-show{
        background: transparent;
        border: 1px solid #fff;
        color: #fff;
        transition: all 0.3s ease-in-out;
        font-weight: 500;
        position: absolute;
        z-index: 1;

        &:hover{
            background: #fff;
            border: 1px solid #fff;
            color: $primary-color;
            transition: all 0.3s ease-in-out;
        }
    }

    h2{
        text-shadow: 2px 3px 5px black;
    }

    .dashboard_container-bg{
        height: 500px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 75%;
        position: relative;
        margin-top: 3rem;

        img{
            height: 88%;
            width: 75%;
            position: absolute;
        }
    }
}
.app_container{
    h3, h4{
        color: #136597;
    }

    .links_app{
        a{
            text-align: start;
            text-decoration: none;
            color: #136597;
            border-bottom: 1px dotted;
            width: 75%;
            font-size: 0.9rem;
            
        }
    }
}

.banking_sideMenu{
    .banking_SideMenu-items{
        h6{
            border-bottom: 1px dotted;
            padding: 0;
            padding-bottom: 5px;
        }

        a{
            text-decoration: none;
            font-size: 12px;
            color: #136597;
        }

        .facebook_icon{
            color: #3b5998;
            transition: color 0.3s;

            &:hover{
                color: #2d4373;
                transition: color 0.3s;
            }
        }

        .twitter_icon{
            color: #00aced;
            transition: color 0.3s;
            
            &:hover{
                color: #0087ba;
                transition: color 0.3s;
            }
        }

        .linkedin_icon{
            color: #007bb6;
            transition: color 0.3s;
            
            &:hover{
                color: #005983;
                transition: color 0.3s;
            }
        }
    }
}

.section_divider{
    h4{
        font-size: 22px;
        color: #136597;
        border-bottom: 1px dotted
    }
    h5{
        font-size: 16px;
        color: #136597;
        border-bottom: 1px dotted
    }

    p{
        font-size: 14px;
    }

    .card_section{
        .card_section-content{
            border: 1px solid;
            height: 30rem;
            box-shadow: 0px 3px 10px #b5b5b5;
            border-color: transparent;
            border-radius: 10px;

            img {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            .content_card{
                height: 19rem;  

                div{
                    a{
                        color:#000843;
                        text-decoration: none;
                        transition: all 0.3s ease-in;

                        &:hover{
                            color: #5E97E4;
                            transition: all 0.3s ease-in;
                        }
                    }
                }
            }
        }
    }
}

.feature_content{
    h4 {
        margin-bottom: 2rem;
        padding: 0;
        color: #007ac1;
        font-size: 24px;
        font-family: 'Calibri',sans-serif;
        font-weight: normal;
        border-bottom: #999;
        border-bottom-style: dotted;
        border-bottom-width: thin;
        /* font-style: italic; */
        line-height: 1.5;
    }
}

.overview_content{
    h6{
        font-weight: bold;
        color: #007ac1;
        margin-bottom: 1rem;
    }
    ul{
        li{
            font-size: 15px;
            padding-bottom: 0.5rem;
            font-weight: 400;
            &::marker{
                color: #007ac1;
            }
        }
    }
}

.pub_container{
    box-shadow: 0px 3px 10px #b5b5b5;
    .pub_content{
        h6{
            color: #136597;
            font-weight: 600;
            font-size: 19px;
            line-height: 1.2;
            margin-bottom: 1.5rem;
        }
        p{
            font-size: 14px;
            margin-bottom: 0.25rem;
        }
        a{
            color: #136597;
            font-size: 14px;

            &:hover{
                text-decoration: none;
            }
        }
    }
}

.banner_img-risk{
    height: 25rem;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;  

    .banner_overlay-ai{
        position: absolute;
        top: 0;
        width: 100%;
        height: 25rem;
        left: 0;
        background: rgba(0,0,0,0.2);
    }
    .banner_content-ai{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p{
            margin-top: 1rem;
            font-size: 1.5rem;
            line-height: 1rem;
        }
    }
}

.risk_bar-btns{
    width: 95%;
    border-bottom: 2px solid #62a4d4;
    .risk_bar-list {
        li{
            cursor: pointer;
            font-size: 12px;
            display: flex;
            align-items: center;
            padding: 0.5rem;
            background-color: #ededed;
            color: #999;
            transition: all 0.3s ;
            &.active{
                background-color: #136597;
                color: white;
            }
            &:hover{
                background-color: #62a4d4;
                color: white;
                transition: all 0.3s ;
            }
        }
    }
}

.paralax_trans{
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.marker-blue{
    li{
        &::marker{
            color: #007ac1;
        }
    }
}
.retailBanner{
    .btn-show{
        margin-top: 1rem;
        background: transparent;
        color: #fff;
        transition: all 0.3s ease-in-out;
        font-weight: 500;
        outline: #fff;
        border: 1px solid #fff;
        z-index: 10;
    
        &:hover{
            background: #fff;
            border: 1px solid #fff;
            color: $primary-color!important;
            transition: all 0.3s ease-in-out;
        }
    }
}

.active_banking{
    display: flex;
    flex-direction: column!important;
    justify-content: flex-start!important;
    align-items: baseline!important;
}
.active_media{
    display: flex;
    flex-direction: column!important;
    justify-content: flex-start!important;
    align-items: baseline!important;
}
.active_Index{
    display: flex;
    flex-direction: column!important;
    justify-content: flex-start!important;
    align-items: baseline!important;
    width: 100%;
}

.ecommerce_row-sec{
    .ecommerce_section{
        transform: translate(0);
        transition: 0.5s all;

        &:hover{
            background: #051c2c;
            color: #fff;
            transform: translate(10px); 
            transition: 0.5s all;
            svg{
                path{
                    stroke: #fff;
                    transition: 0.5s all;
                }
            }
        }
    }
}

.hr_content{
    position: absolute;
    z-index: 1;
    left: 5%;
    width: 45%;
    color: #fff;

    h1{
        font-size: 4.5rem;
    }
}

.overlay_bg{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.15);
    z-index: 0;
}

.jobs_container{
    h1{
        font-size: 3rem;
    }
    .jobs_btn{
        border-radius: 0;
        border: 1px solid #007ac2;
        color: #007ac2;

        &:hover{
            background: #007ac2;
            color: #fff;
        }
    }
}
.job_categories{
    grid-template-columns: 1fr 1fr 1fr;
    .job_category{
        border: 1px solid rgba(0,0,0,0.2);
        padding: 1rem;
        height: 12rem;

        &.active_overlay{
            border: 4px solid #fff;
            box-shadow: 0 0 10px #999;
            overflow: hidden;
        }

        &:hover{
            border: 4px solid #fff;
            overflow: hidden;
        }
        .overlay-border{
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            height: 12rem;
        }
        .jobBg{
            background-position: center center;
            background-size: cover;
        }
        .overlay-bg{
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            position: absolute;
            top: 0;
            right: 0px;
            z-index: 3;
        }
        .job_icon{
            width: 48px;
            fill: #35AC46;
        }
        h4{
            font-weight: 300;
        }
    }
}

/* Media query for 992px breakpoint */
@media screen and (max-width: 992px) {
    .job_categories {
      grid-template-columns: 1fr 1fr;
    }
    .jobs_container{
        h1{
            font-size: 2rem;
        }
    }
}

/* Media query for 768px breakpoint */
@media screen and (max-width: 768px) {
    .job_categories {
        grid-template-columns: 1fr;
    }
    .jobs_container{
        h1{
            font-size: 2rem;
        }
    }
}

.logo_banner
 .container{
    .logo_shift{
        h1 {
        font-size: 5.3rem;
        text-shadow: 4px 4px #0071c2;
    }
}
}
  
  @media screen and (max-width: 768px) {
    .logo_banner
        .container{
            .logo_shift{
                h1 {
                font-size: 3rem;
                text-shadow: 4px 4px #0071c2;
            }   
        }
  }
}


.search_bar{
    border: none!important;
    border-bottom: 1px solid rgba(0,0,0,0.1)!important;

    &:focus{
        outline: none!important;
        box-shadow: none!important;
    }
    
}

.job_contents{
    display: flex;
    flex-direction: column;
    align-items: center;
    .job_content{
        border: 1px solid rgba(0,0,0,0.1);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 2rem;
        gap: 0.5rem;

        &:hover{
            // border: 1px solid #007ac2;
            box-shadow: #d3d3d3 0px 0px 5px 5px;
        }

        h6,p{
            font-weight: 200;
        }

        h3{
            font-weight: 400;
        }

        button{
            align-self: flex-end;
        }
    }
}

.shadow_custom{
    box-shadow: 0 8px 20px rgba(0,0,0,0.25) inset;
}

.values_list{
    li{
        &::marker{
            color: #007ac2;
        }
    }
}

.img_content-academy{
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .overlay_bg{
        position: absolute;
        // height: 15rem;
        width: 100%;
        background: rgba(0,0,0,0.05);
        bottom: 0;
        z-index: 1;

        .text_content{
            left: 3%;
            h2{
                line-height: 3rem;
            }
        }

        img{
            width: 100%;
        }

        h1{
            line-height: 2.5rem!important;
            margin-bottom: 1.5rem!important;
        }
        p{
            margin-bottom: 1rem;
        }
    }

}

.academy_paralax{
    height: 24rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 500px;
}

.hide_carousel{
    display: none!important;
}


.webinars-heading{
    h3{
        color: #007ac1;
    }
}

.webinars_container{
    .webinar_content{
        .webinar_content-container{
            height: 33vw;
            border: 1px solid rgba(0,0,0,0.15);
            border-radius: 15px 15px 0 0;
            transition: all 1s;
            &:hover{
                border-color: #fff;
                box-shadow: 10px 10px 15px rgba(0,0,0,0.15);
                transition: all 1s;
            }
            img{
                border-radius: 15px 15px 0 0;
            }
            p, a{
                color: #007ac1;
                text-decoration: none;
            }
            a{
                font-size: smaller;
                font-weight: bold;

                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}

.services-heading{
    h3{
        color: #007ac1;
    }
}
.services_container{
    .service_content{
        .service_content-container{
            height: 55vw;

            .service_img{
                border-radius: 50%;
                width: 15rem;
                height: 15rem;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                border: 1px solid #007ac1;
                filter: none;
                transform: scale(1);
                transition: all 0.3s ease-in-out;

                &:hover{
                    filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.6));
                    transform: scale(1.05);
                    transition: all 0.3s ease-in-out;
                }
            }
            h4, p, a{
                color: #007ac1;
                text-decoration: none;
            }
            a{
                font-size: smaller;
                font-weight: light;

                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}

.hero_banner{
    height: 650px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    .hero__overlay{
        background: rgba(0,0,0,0.3);
        position: absolute;
        width: 100%;
        height: 650px;
    }

    h1{
        color: #fff;
        text-align: center;
        width: 60%;
        font-weight: bold;
        line-height: 4rem;
        z-index: 1;
    }
}

.white_scale{
    filter: brightness(0) invert(1) brightness(1);
}
$color_1: #4c4a4b;
$color_2: #9d9ba7;
$color_3: teal;
$font-family_1: 'Roboto', sans-serif;
$background-color_1: #f9f9f9;
$background-color_2: #fcfcfc;
$background-color_3: #029f56;
$background-color_4: transparent;

@keyframes fadein {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes bottomslide {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
@keyframes rightslide {
	0% {
		height: 0;
	}
	100% {
		height: 100%;
	}
}
@keyframes spin {
	10% {
		width: 0;
		height: 0;
		border-width: 6px;
	}
	30% {
		width: 0;
		height: 0;
		border-radius: 50%;
		border-width: 1em;
		transform: rotate(0deg);
		border-color: rgb(199,218,245);
	}
	50% {
		width: 2em;
		height: 2em;
		border-radius: 50%;
		border-width: 4px;
		border-color: rgb(199,218,245);
		border-right-color: rgb(89,152,239);
	}
	70% {
		border-width: 4px;
		border-color: rgb(199,218,245);
		border-right-color: rgb(89,152,239);
	}
	90% {
		border-width: 4px;
	}
	100% {
		width: 2em;
		height: 2em;
		border-radius: 50%;
		transform: rotate(720deg);
		border-color: transparent;
	}
}

.captcha {
	background-color: $background-color_1;
	border: 2px solid #d3d3d3;
	border-radius: 5px;
	color: $color_1;
	display: flex;
	justify-content: center;
	align-items: center;
    transform: scale(0.7);
}
.text {
	font-size: 1.75em;
	font-weight: 500;
	margin-right: 1em;
}
.spinner {
	position: relative;
	width: 2em;
	height: 2em;
	display: flex;
	margin: 2em 1em;
	align-items: center;
	justify-content: center;
}
input[type="checkbox"] {
	position: absolute;
	opacity: 0;
	z-index: -1;
	&:checked+.checkmark {
		animation: 2s spin forwards;
		>span {
			animation: 1s fadein 1.9s forwards;
			&:after {
				animation: .3s bottomslide 2s forwards;
			}
			&:before {
				animation: .5s rightslide 2.2s forwards;
			}
		}
	}
}
input[type="checkbox"]+.checkmark {
	display: inline-block;
	width: 2em;
	height: 2em;
	background-color: $background-color_2;
	border: 2.5px solid #c3c3c3;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	span {
		content: '';
		position: relative;
		margin-top: -3px;
		transform: rotate(45deg);
		width: .75em;
		height: 1.2em;
		opacity: 0;
	}
	>span {
		&:after {
			content: '';
			position: absolute;
			display: block;
			height: 3px;
			bottom: 0;
			left: 0;
			background-color: $background-color_3;
		}
		&:before {
			content: '';
			position: absolute;
			display: block;
			width: 3px;
			bottom: 0;
			right: 0;
			background-color: $background-color_3;
		}
	}
}
.logo {
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.5em 1em;
	img {
		height: 3em;
		width: 2em;
	}
	p {
		color: $color_2;
		margin: 0;
		font-size: 1em;
		font-weight: 700;
		margin: .4em 0 .2em 0;
	}
	small {
		color: $color_2;
		margin: 0;
		font-size: .8em;
	}
}

@media screen and (max-width: 500px) {
	.captcha {
		flex-direction: column;
	}
	.text {
		margin: .5em !important;
		text-align: center;
	}
	.logo {
		align-self: center !important;
	}
	.spinner {
		margin: 2em .5em .5em .5em !important;
	}
}

.gov_container{
    // .slick-track{
    //     height: 450px;
    // }
    .geo_thumb{
        height: 70px;

        .geo_thumb-box{
            height: 70px;
        }
    }
}

.overlay_bg-gov{
    position: absolute;
    clip-path: polygon(0 0, 70% 0, 50% 100%, 0% 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 25%, transparent 60%);
    height: 100%;
}

.gov-card{
    padding: 15px 13px 0 14px;
    border: 1px solid #eaeaea;
    border-radius: 0px;
    background: white;
    box-shadow: 0px 3px 10px #b5b5b5;
    color: #757575;
    display: inline-block;
    min-height: 600px !important;
    position: relative; 
    // margin: 1%;
}



// .slick-prev{
//     &::before{
//        color: #001d6e;

//     }
// }
// .slick-next{
//     &::before{
//         color: #001d6e;

//     }
// }


@media (max-width:768px) {
    .compliance_section{
        .row{
            display: flex;
            flex-direction: column;
            text-align: center;
            .col-md-6{
                width: 100%;

                img{
                    width: 75%!important;;
                }
            }
        }
    }
    #services_scroll, #services_scroll1{
        .col-md-4{
            width: 50%!important;
        }
    }
    #business_scroll{
        .col-md-4{
            width: 50%!important;
            .business__card-container{
                height: 60vw!important;
            }
        }
    }
    .content{
        .img_content{
            .overlay_bg{
                clip-path: none!important;
                background: rgba(0,0,0,0.4)!important;
            }
        } 
    } 
    .content{
        .carousel_video{
            height: 600px;
        }
    }
    .text_content{
        text-align: center;
        top: 15%!important;
        &.col-md-6{
            width: 66.6666667%!important;
            left: 4rem!important;
        }
        h1{
            font-size: 4rem!important;
            line-height: 4.5rem!important;
            div{
                color: #fff!important;
            }
        }
        p{
            font-size: 2.5rem!important;
            line-height: 3rem!important;
            div{
                color: #fff!important;
            }
        }
        .gap-8{
            align-items: center;
            justify-content: center;
            width: 75%;
            margin-top: 3.5rem;
        }
        .btn-show{
            transform: scale(1.8);
        }
    }
    .slick-prev{
        left: 6%!important;
    }
}

@media (max-width:425px) {
    body{
        .navbar{
            width: 100vw!important;
        }
    }
    #business_scroll{
        .col-md-4{
            width: 100%!important;

            .business__card-container{
                height: 115vw!important;
            }
        }
    }

    #services_scroll, #services_scroll1{
        .col-md-4{
            width: 100%!important;
        }
    }
    .container_content{
        .left_links{
            .logo{
                margin: 0.6em 1em 0 1em!important;
            }
        }
    }
    .text_content{
        top: 20%!important;
        &.col-md-6{
            width: 75%!important;
            left: 3.5rem!important;
        }
        h1{
            font-size: 2.5rem!important;
            line-height: 2.5rem!important;
        }
        p{
            font-size: 1.5rem!important;
            line-height: 1.5rem!important;  
        }
        .gap-8{
            margin-top: 0rem!important;
            width: 60%!important;
        }
        .btn-show{
            transform: scale(1.1);
        }
    }
    .clients__container{
        .slick-arrow{
            display: none!important;
        }
    }
}

.career_banner{
    height: 650px;
    background-position: center center;
}


.overlay_bg-career{
    position: absolute;
    top: 0;
    height: 650px;
    width: 100vw;
    background: rgba(0, 0, 0, 0.15);
    z-index: 0;
}

.subMenuMind{
    position: absolute;
    bottom: -4.5rem;
    list-style: none;
    right: 3%;
    margin: 0;
    background: #dedede;
    border: 1px solid #fff;
    color: #777;
    padding: 0;
    transition: 0.3s all linear;
    display: block;
    font-size: 14px;
}

.subMenuMind li{
    padding: 0.8em 1em;
    border-bottom: 1px solid lightgray;
}

.subMenuMind li:hover{
    background: #051c2c!important;
    color: #fff!important;
}

.active{
    .mind_map-items_item{
        background: #28367d;
        color: #fff;    
    }
}

.mind_map {
    .mind_map-title {
        &.pointer {
            cursor: pointer;
        }
    }
    .mind_map-items{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // padding-left: 1.5rem;
        // padding-right: 2.5rem;
        gap: 1rem;
        .mind_map-items_item{
            border: 1px solid;
            flex: 2;
            text-align: center;
            padding: 1rem;
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover{
                background-color: #28367d;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}

.employee_card{
    display: flex;
    width: 32%;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
    border: 1px solid;
    padding: 10px;
}

.mind_map-items_item {
    &.active{
        background: #28367d;
        color: #fff;
        width: 32%;
    }
}

.clodeModal{
    &:hover{
        font-size: larger;
    }
}

.submenu-container {
    max-height: 400px;
    overflow-y: auto;
    transition: max-height 0.3s ease;
}

.pointer{
    cursor: pointer;
}

.category_container{
   color: #000!important;
   text-decoration: none;
}

.category_container{
    &.active{
        background: #28367d;
        color: #fff!important;
    }
}

#pc_view {
    a {
        h6{
            color: #fff;
        }
    }
}

.arabic_text {
    direction: rtl;
}

.font_arabic1{
    font-size: 2.5rem;
    font-weight: 600;
}

.font_arabic{
    font-size: 2rem;
    line-height: 3rem!important;
}

.lineHeight-4 {
    line-height: 3.5rem;
}

.section-paralax {
    position: relative;
    height: 700px;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
  }

  
.unesco-container {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
  
  .unesco-cards .unesco-card {
    position: relative;
    height: 650px;
    width: 20vw;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
    transition: width 0.3s ease-in-out;
  }
  
  .unesco-cards .unesco-card:hover {
    width: 30vw;
    transition: width 0.3s ease-in-out;
  }
  
  .unesco-cards .unesco-card .overlay {
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    height: 80%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
    z-index: 1;
    border-radius: 15px;
  }
  
  .unesco-cards .unesco-card .unesco-card_text {
    position: relative;
    z-index: 3;
    color: #fff;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: end;
  }
  
  .unesco-cards .unesco-card .unesco-card_social {
    opacity: 0;
    display: none;
    transition: all 0.8s ease-in;
  }
  
  .unesco-cards .unesco-card:hover .unesco-card_social {
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.15rem;
    position: absolute;
    top: 70%;
    z-index: 5;
    transition: all 0.3s ease-in;
  }
  
  .unesco-cards .unesco-card:hover .unesco-card_social svg {
    cursor: pointer;
    width: 40px;
    height: 24px;
    fill: #fff;
  }


  .gsc-input{
    background: none!important;
  }

  .gsc-search-button-v2 {
    padding: 10px 30px!important;
    background-image: linear-gradient(180deg, #4d90fe, #4787ed)!important;
}

.button_read_more {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 15px;
    background-color: #001d6e;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    border: 1px solid transparent;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  }
  
  .button_read_more:hover {
    background-color: transparent;
    border: 1px solid #001d6e;
    color: #001d6e;
  }
  

  @media (max-width: 576px){
    .flex-xs-column{
        flex-direction: column;
    }
  }