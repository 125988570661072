.btn {
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.btn:hover {
  background-color: white;
  color: #0e1d34;
}

.btn.active {
  background-color: white;
  color: #0e1d34;
}

.services-container {
  position: relative;
  height: 650px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 650px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.services-container h1 {
  position: relative;
  z-index: 2;
}

.services-container_menu a:hover h5,
.services-container_menu a:hover svg {
  color: #0e1d34;
}

.bg-dark-menu {
  background-color: #0e1d34;
}

.services-container_menu a.active {
  background-color: white;
  color: #0e1d34 !important;
}

.services-container_menu a.active h5,
.services-container_menu a.active svg {
  color: #0e1d34 !important;
}

.services-container_menu h5 {
  color: #fff;
}

.btn {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn:hover {
  background-color: white;
  color: #0e1d34;
}

.btn.active {
  background-color: white;
  color: #0e1d34 !important;
}

.bg-dark-menu svg {
  color: #fff;
}
