.see_news svg{
    transform: translateX(0);
    transition: all 0.3s ease-in-out;
}

.see_news:hover svg{
    transform: translateX(10px);
    transition: all 0.2s ease-in-out;
}

.news_img_container{
    height: 60vh;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat
}

.news__title img{
    width: 15%;
}

.news__title span{
    font-size: 3.5rem;
    padding-top: 0.3rem;
}

.news__title{
    font-size: 3.5rem;
}

.container_news{
    padding-left: 4rem;
}