.solution_sections .overlay {
  width: 100%;
  position: absolute;
  height: 20rem;
  background-color: rgba(0, 0, 0, 0.3);
  left: 0;
}

.solution_sections div.position-relative {
  box-shadow: none;
  border: 1px solid transparent;
  transform: translate(0);
  transition: all 0.3s ease-in;
}

.solution_sections div.position-relative:hover {
  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.4);
  border: 1px solid #fff;
  transform: translateY(-5px) translateX(5px);
  transition: all 0.3s ease-in;
}

.solution_sections {
  padding: 0 3rem;
}
