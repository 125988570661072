footer {
  height: 350px;
  background-color: #051c2c;
}

footer .footer_siteMap {
  align-items: start;
}

footer .footer_siteMap h5 {
  font-weight: 400;
}

.footer_siteMap div {
  align-items: start;
}

footer .footer_siteMap a:hover h5 {
  font-weight: 500;
}

footer .footer_social a {
  border-radius: 100%;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  transition: all 0.3s ease-in;
}

footer .footer_social a:hover {
  background-color: #fff;
  color: #051c2c;
  transition: all 0.3s ease-in;
}

footer .footer_copyright {
  background: #031420;
  width: 100%;
  left: 0;
  padding: 10px;
  text-align: center;
  bottom: 0;
  color: #fff;
}

footer .footer_copyright h5 {
  margin: 0;
  font-weight: 100;
  font-family: var(--font-poppins);
}

.footer_logo {
  width: 15%;
}

.footer_links a {
  text-decoration: none !important;
  color: #fff !important;
}

.footer_links li:hover a {
  border-bottom: 2px solid;
}
