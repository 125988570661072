
.solution_container h4{
    color: #04406A
}

.solution_container-content .linkHeader svg{
    transform: translateX(0);
    transition: all 0.3s ease-in
}

.solution_container-content .linkHeader:hover svg{
    transform: translateX(10px);
    transition: all 0.2s ease-in
}
.solution_container .content_title{
    font-size: 3.5rem;
}

.solution_container-content .overlay{
    background-color: rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}