.request-demo-container {
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-title {
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  color: #001f3d;
  margin-bottom: 20px;
}

.title_seperator {
  border-bottom: 2px solid #001f3d;
  opacity: 1;
  width: 10%;
}

.request-demo-form {
  max-width: 70%;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  padding: 1.5rem;
  gap: 0;
  background-color: #fff;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.15);
}

.request-demo-form .form-group {
  width: 25%;
  padding: 1rem 0;
}

.form-group label {
  font-family: var(--font-poppins);
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

.form-group input,
.form-group textarea {
  height: auto;
  padding: 0.75rem 1.1rem;
  font-size: 1rem;
  border-color: #d4d7e5;
  width: 100%;
  border-radius: 4px;
  outline: none;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.submit-btn {
  background-color: #001f3d;
  height: 3rem;
  color: white;
  width: 25%;
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #012d58;
}
.form-title p {
  font-size: 1.5rem;
}
