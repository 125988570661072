@import url("https://fonts.googleapis.com/css2?family=Alatsi&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --font-poppins: "Poppins", sans-serif;
  --bs-heading-color: #001f3d !important;
  --primary-bg: #001f3d;
  --font-alatsi: "Alatsi", sans-serif;
  /* --font-bentham : "Bentham", serif; */
  /* --font-baskerville : "Baskervville", serif; */
  /* --font-sahitya : "Sahitya", serif; */
}

html {
  width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.w-60 {
  width: 60%;
}

.w-45 {
  width: 45%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-20 {
  width: 20%;
}

.font--poppins {
  font-family: var(--font-poppins);
}

.font--alatsi {
  font-family: var(--font-alatsi);
}

.primary-bg {
  background-color: var(--primary-bg);
}

.media_objects .media__container {
  padding: 2rem 0;
  background-color: #f9f9f9;
}

.media_objects .media__title h2 {
  font-size: 2rem;
  text-align: center;
  color: #333;
  font-weight: bold;
}

.media_objects .media__card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
  margin-bottom: 2rem;
}

.media_objects .media__card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.media_objects .media__video {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.media_objects .media__video iframe {
  border-radius: 10px 10px 0 0;
}

.media_objects .media__content {
  padding: 1.5rem;
  text-align: left;
  height: 20rem;
}

.media_objects .media__content h3 {
  font-size: 1.25rem;
  color: #222;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.media_objects .media__content p {
  font-size: 0.9rem;
  color: #555;
  line-height: 1.4;
}

/* Slider Arrows */
.media_objects .slick-prev,
.media_objects .slick-next {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding-top: 0.5rem;
}

.media_objects .slick-prev:hover,
.media_objects .slick-next:hover {
  background: #f0f0f0;
}

.media_objects .slick-prev:before,
.media_objects .slick-next:before {
  font-size: 35px;
  color: #000;
}
