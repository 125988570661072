.services_bg {
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.banner_bg {
    height: 400px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}