.overviewClass {
  text-align: start;
  width: 35%;
  left: -25rem;
  top: 2rem;
}

.overviewClass h1 {
  line-height: 3rem;
}
