.consultancySection {
  text-align: left;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 26rem;
  /* width: 100%!important; */
  background-color: #fff;
  transform: translateY(0);
  transition: all 0.3s ease-in;
}

.consultancySection:hover {
  transform: translateY(-10px);
  box-shadow: 3px 3px 3px 6px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in;
}

.consultancySection_banner {
  height: 200px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.text_content-Consultancy {
  padding-top: 1rem;
}

.industryPage .section-slide {
  background-color: white;
  border-radius: 10px;
  height: 30rem;
  border: 1px solid #fff;
}

.industryPage .slick-slide:hover {
  transform: translateY(-10px);
  transition: all 0.3s ease-in;
}

.industryPage .slick-slide {
  transform: translateY(0);
  transition: all 0.3s ease-in;
}

.industryPage .section-slide .text-content {
  margin: 0 10px;
  padding: 10px;
}

.industryPage .slick-slide {
  padding: 0 15px;
}

.industryPage .slick-track {
  display: flex;
}

.industryPage .slick-list {
  padding: 0 10px;
}
