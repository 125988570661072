.intro_container1 span {
  margin: 4rem 0;
}

.intro_container1 .container {
  max-width: 1140px !important;
}

.intro_container1 .h-header {
  height: 4rem;
}

.intro_container1 span {
  width: 2rem;
  height: 0.2rem;
  background-color: #001f3d;
}

.intro_container1 h4 {
  font-family: var(--font-poppins);
  color: #001f3d !important;
  font-weight: 400;
}

.landingTitle h4 {
  color: #fff !important;
}

.landingTitle span {
  background-color: #fff !important;
}
