.job-container{
    background: linear-gradient(#051c2c, #0a3d62);
}

.job-wrapper{
    background-color: #fff;
    border-radius: 15px;
}

.apply-button{
    color: #051c2c; 
    text-decoration: none; 
    padding: 10px 25px; 
    border: 1px solid #051c2c; 
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    font-size: large;
}

.apply-button:hover{
    background-color: #051c2c;
    color: white;
    transition: all 0.3s ease-in-out;
}