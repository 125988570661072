.compliance-container .primary-bg {
  border-radius: 0 0 15px 0;
}

.play_container {
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.play_container:hover svg {
  fill: #fff;
  transition: all 0.5s ease-in;
}
.play_container:hover {
  background-color: #fff;
  transition: all 0.3s ease-in;
}

.play_container:hover svg {
  fill: var(--primary-bg);
  transition: all 0.5s ease-in;
}

.compliance_box {
  width: 50%;
}
