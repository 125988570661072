.banner_info {
  height: 500px;
  background-position: center center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 500px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  text-align: center;
}

.text_content-banner {
  position: relative;
  z-index: 3;
}

.solutionClass h1,
.solutionClass h4 {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.industryClass h1,
.industryClass h4 {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.consultancyClass {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  left: 5%;
  text-align: start;
}

.consultancyClass h1 {
  font-size: 3rem !important;
  color: #fff;
  text-shadow: 2px 1px #000;
}

.vfdsClass {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  left: 5%;
  text-align: start !important;
}

.vfdsClass h1 {
  font-size: 4rem !important;
  color: #fff;
  text-shadow: 2px 1px #000;
}

.vfds_banner-width {
  width: 12%;
}

.vfds_top-banner_width {
  width: 12%;
}

.vfdsClass h4 {
  color: #fff;
  font-size: 1.2rem !important;
}
