$lightGreen : #b9e7e7;
$primary-color : #051c2c;


.dashboard{
    display: flex;
    .sidebar{
        background-color: #f9fafb;
        height: auto;
        flex: 1;
        box-shadow: inset 0 0.125rem 1rem 3px rgba(0, 0, 0, 0.075) !important;
        .sidebar_logo{
            border-right: 0px;
        }

        ul{
            display: flex;
            gap: 0.2rem;
            flex-direction: column;
            list-style: none;
            padding: 0;

            li{
                cursor: pointer;
                color: #212B36;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0.5rem;
                padding: 20px;

                &.active_page{
                    background: rgba(145, 158, 171, 0.08);
                }

                svg{
                    font-size: 1.5rem;
                    color: #212B36;
                }

                &:hover{
                    background: rgba(145, 158, 171, 0.08);
                }
            }
        }
    }
    .main-content{
        background: #f9fafb;
        flex: 5;
        height: auto;
        .tableStyle{
            padding: 1rem!important;
            background: white;
            height: calc(87vh - 100px); 
            overflow-y: auto;
            overflow-x: hidden;
            // border: 1px solid rgba(0,0,0,0.1);
            border-radius: 10px;
            box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
        }
        // .table{
        //     border: 1px solid rgba(0,0,0,0.2);
        // }
        .logout_bar{
            width: 100%;
            padding: 13px;
            box-shadow: inset -2px 3px 0.25rem 0px rgba(0, 0, 0, 0.075) !important;
            .user_container{
                width: 2.5rem;
                height: 2.5rem;
                display: flex;
                border-radius: 50%;
                align-items: center;
                cursor: pointer;
                    .menuLog{
                        opacity: 1;
                        color: black;
                        width: 90%;
                        height: 90%;
                    }
                }
                &:hover{
                    .overlay_layer{
                    position: absolute;
                    background-color: rgba(0,0,0,0.6);
                }
            }

            .overlay_layer{
                position: absolute;
                
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 50%;
                opacity: 0;
            }
            
            ul{
                position: absolute;
                top: 9%;
                width: 9rem;
                right: 3rem;
                font-size: 14px;
                list-style: none;
                display: flex;
                flex-direction: column;
                box-shadow: 0px 5px 5px -3px rgba(145, 158, 171, 0.2), 0px 8px 10px 1px rgba(145, 158, 171, 0.14), 0px 3px 14px 2px rgba(145, 158, 171, 0.12);
                background: #fff;
                padding: 0;
                margin: 0;
                
                li{
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    gap: 1rem;
                    padding: 1rem 1rem;
                    &:hover{
                        background: rgba(145, 158, 171, 0.08);
                    }

                    svg{
                        width: 20%;
                        height: 20%;
                    }
                    a{
                        text-decoration: none;
                        color: #000;
                        
                    }
                }
                hr{
                    margin:0;
                    padding: 0;
                }
            }
        }
    }
}

//LOGIN & REGISTER
.auth{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: $lightGreen;
    background-repeat: no-repeat;
    background-size: cover;
}

h1{
    font-size: 20px;
    color: teal;
    margin-bottom: 20px;
}

.login_form{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    flex-direction: column;
    padding: 50px;
    background: white;
    width: 390px;
    gap: 1.5rem;
    height: 35vw;

    h5{
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }

    h1{
        align-self: center;
    }

    input{
        border-radius: 5px;
    }

    // input{
    //     padding: 10px;
    //     border: none;
    //     border-bottom: 1px solid gray
    // }
    .error_border{
        border-bottom: 1px solid red!important
    }

    // button{
    //     padding: 10px;
    //     border: none;
    //     background-color: teal;
    //     color: #fff;
    //     cursor: pointer
    // }

    .error_msg{
        color: red;
        font-weight: 600;
    }

    p {
        font-style: 12px;
        color: red;
        text-align: center;
    }
    
    span{
        font-size: 12px;
        text-align: center;
    }
}

.reg_form{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    flex-direction: column;
    padding: 50px;
    background: white;
    width: 390px;
    gap: 1.5rem;
    height: 45vw;

    h5{
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }

    h1{
        align-self: center;
    }

    input{
        border-radius: 5px;
    }

    // input{
    //     padding: 10px;
    //     border: none;
    //     border-bottom: 1px solid gray
    // }
    .error_border{
        border-bottom: 1px solid red!important
    }

    // button{
    //     padding: 10px;
    //     border: none;
    //     background-color: teal;
    //     color: #fff;
    //     cursor: pointer
    // }

    .error_msg{
        color: red;
        font-weight: 600;
    }

    p {
        font-style: 12px;
        color: red;
        text-align: center;
    }
    
    span{
        font-size: 12px;
        text-align: center;
    }
}
.edit_form, form{
    display: flex;
    flex-direction: column;
    // padding: 50px;
    background: white;
    width: 100%;
    gap: 20px;

    h5{
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }

    // input{
    //     padding: 10px;
    //     border: none;
    //     border-bottom: 1px solid gray
    // }
    .error_border{
        border-bottom: 1px solid red!important
    }

    // button{
    //     padding: 10px;
    //     border: none;
    //     background-color: teal;
    //     color: #fff;
    //     cursor: pointer
    // }

    .error_msg{
        color: red;
        font-weight: 600;
    }

    p {
        font-style: 12px;
        color: red;
        text-align: center;
    }
    
    span{
        font-size: 12px;
        text-align: center;
    }
}
.add_form, form{
    display: flex;
    flex-direction: column;
    // padding: 50px;
    background: white;
    width: 100%;
    gap: 20px;

    h5{
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }

    // input{
    //     padding: 10px;
    //     border: none;
    //     border-bottom: 1px solid gray
    // }
    .error_border{
        border-bottom: 1px solid red!important
    }

    // button{
    //     padding: 10px;
    //     border: none;
    //     background-color: teal;
    //     color: #fff;
    //     cursor: pointer
    // }

    .error_msg{
        color: red;
        font-weight: 600;
    }

    p {
        font-style: 12px;
        color: red;
        text-align: center;
    }
    
    span{
        font-size: 12px;
        text-align: center;
    }
}

.ReactModal__Content{
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px 0!important;
    max-width: 800px;
    border-radius: 25px!important;
    left: 23%!important;
    top: 10%!important;
    height: fit-content;    
}

.ReactModal__Content_navbar{
    max-width: 400px!important;
    overflow: hidden!important;
    left: 32%!important;
    top: 20%!important;
}

.ReactModal__Overlay{
    background-color: rgba(0, 0, 0, 0.5)!important;
}

.sticky-header {
    position: sticky;
    top: -1rem;
    background-color: #f2f2f200; /* You can customize the background color as needed */
  }

.section_rows{
    font-size: 13px;
    .truncate {
        max-width: 150px;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
    
        &.img_size{
            h2{
                font-size: 0.7rem;
            }
            img{
                width: 60%;
            }
        }
    }
}  

.react-reveal{
    width: 100%;
}

.truncate {
    max-width: 160px;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
}