ul.timeline_ul {
    --col-gap: 2rem;
    --row-gap: 2rem;
    --line-w: 0.25rem;
    display: grid;
    grid-template-columns: var(--line-w) 1fr;
    grid-auto-columns: max-content;
    column-gap: var(--col-gap);
    list-style: none;
    // width: min(60rem, 90%);
    margin-inline: auto;
  }
  
  /* line */
  ul.timeline_ul::before {
    content: "";
    grid-column: 1;
    grid-row: 1 / span 20;
    background: rgb(225, 225, 225);
    border-radius: calc(var(--line-w) / 2);
  }
  
  /* columns*/
  
  /* row gaps */
  ul li.timeline__list:not(:last-child) {
    margin-bottom: var(--row-gap);
  }
  
  /* card */
  ul li.timeline__list {
    grid-column: 2;
    --inlineP: 1.5rem;
    margin-inline: var(--inlineP);
    grid-row: span 2;
    display: grid;
    grid-template-rows: min-content min-content min-content;
  }
  
  /* date */
  ul li.timeline__list .date {
    --dateH: 3rem;
    height: var(--dateH);
    margin-inline: calc(var(--inlineP) * -1);
  
    text-align: center;
    background-color: var(--accent-color);
  
    color: white;
    font-size: 1.25rem;
    font-weight: 700;
  
    display: grid;
    place-content: center;
    position: relative;
  
    border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
  }
  
  /* date flap */
  ul li.timeline__list .date::before {
    content: "";
    width: var(--inlineP);
    aspect-ratio: 1;
    background: var(--accent-color);
    background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
    position: absolute;
    top: 100%;
  
    clip-path: polygon(0 0, 100% 0, 0 100%);
    right: 0;
  }
  
  /* circle */
  ul li.timeline__list .date::after {
    content: "";
    position: absolute;
    width: 2rem;
    aspect-ratio: 1;
    background: var(--bgColor);
    // border: 0.3rem solid var(--accent-color);
    border-radius: 50%;
    top: 50%;
  
    transform: translate(50%, -50%);
    right: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  
  /* title descr */
  ul li.timeline__list .title,
  ul li.timeline__list .descr {
    background: var(--bgColor);
    position: relative;
    padding-inline: 1.5rem;
  }
  ul li.timeline__list .title {
    overflow: hidden;
    padding-block-start: 1.5rem;
    padding-block-end: 1rem;
    font-weight: 500;
  }
  ul li.timeline__list .descr {
    padding-block-end: 1.5rem;
    font-weight: 300;
  }
  
  /* shadows */
  ul li.timeline__list .title::before,
  ul li.timeline__list .descr::before {
    content: "";
    position: absolute;
    width: 90%;
    height: 0.5rem;
    background: rgba(0, 0, 0, 0.5);
    left: 50%;
    border-radius: 50%;
    filter: blur(4px);
    transform: translate(-50%, 50%);
  }
  ul li.timeline__list .title::before {
    bottom: calc(100% + 0.125rem);
  }
  
  ul li.timeline__list .descr::before {
    z-index: -1;
    bottom: 0.25rem;
  }
  
  @media (min-width: 40rem) {
    ul.timeline_ul {
      grid-template-columns: 1fr var(--line-w) 1fr;
    }
    ul.timeline_ul::before {
      grid-column: 2;
    }
    ul li.timeline__list.odd {
      grid-column: 1;
    }
    ul li.timeline__list:nth-child(even) {
      grid-column: 3;
    }
  
    ul li.timeline__list:nth-child(2) {
      grid-row: 2/4;
    }
  
    ul li.timeline__list.odd .date::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%);
      left: 0;
    }
  
    ul li.timeline__list.odd .date::after {
      transform: translate(-50%, -50%);
      left: calc(100% + var(--col-gap) + var(--line-w) / 2);    
    }
    ul li.timeline__list.odd .date {
      border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
    }
  }

  li.timeline__list .item1::after{
    border: 1px solid #41516C
  }
  li.timeline__list .item2::after{
    border: 1px solid #FBCA3E
  }
  li.timeline__list .item3::after{
    border: 1px solid #E24A68
  }
  li.timeline__list .item4::after{
    border: 1px solid #1B5F8C
  }
  
  .credits {
    margin-top: 1rem;
    text-align: right;
  }
  .credits a {
    color: var(--color);
  }
  
  .w-85{
    width: 85%;
  }