nav {
  max-height: 4.5rem;
  background-color: transparent !important;
  border: 1px solid;
  position: fixed !important;
  top: 0;
  border: 0;
  z-index: 20;
}

nav.active {
  background-color: #051c2c !important;
  opacity: 0.9;
}

nav .nav_logo {
  width: 12%;
  padding: 0;
}

nav .nav_menu ul {
  list-style: none;
  gap: 4rem;
}

nav .nav_menu ul li {
  color: #fff;
  padding: 0;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

nav .nav_menu ul li a.activeLink {
  border-bottom: 4px solid #fff;
}

nav .nav_menu ul li a.selected,
nav .nav_menu ul li a {
  position: relative;
  color: #fff;
  text-decoration: none !important;
  font-size: 18px;
  font-family: var(--font-poppins);
  font-weight: medium;
  padding: 1.4rem 0;
  transition: all 0.3s ease-in;
}

nav .nav_menu ul li a.selected::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transition: width 0.3s ease-in-out;
}

nav .nav_menu ul li:hover a.selected::before {
  width: 100%;
}

nav .nav_menu ul li a .selected.active {
  border-bottom: 3px solid #fff;
}

nav .nav_search {
  display: flex;
  justify-content: flex-end;
}

nav .nav_hamburger {
  cursor: pointer;
  position: relative;
  z-index: 10;
}

/* Mobile Menu styles */
.mobile_menu1 {
  position: fixed;
  top: -104vh;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(5, 28, 44, 0.95);
  transition: top 0.3s ease-in-out;
  z-index: 5;
}

.mobile_menu1.open {
  top: 0;
  display: block;
  padding: 2rem 0;
}

.mobile_menu1 ul {
  list-style: none;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
}

.mobile_menu1 ul li {
  color: #fff;
}

.mobile_menu1 ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-family: var(--font-poppins);
  font-weight: medium;
}
