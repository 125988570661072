.border-overview-seperator {
  border-right: 1px solid;
  padding-right: 0.8rem;
  height: 30vh;
  display: flex;
  align-items: start;
}

.ceo_section {
  position: relative;
}

.ceo_section .overlay-overview {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  height: 600px;
  top: 0;
}

.ceo_section .overview_text-content {
  position: relative;
  z-index: 2;
}

.ceo_section .overview_text-content h1 {
  color: #0d1450;
  width: 75%;
}

.part2_ceo p {
  width: 80%;
  padding: 0 3rem;
}

.part1_ceo p {
  padding: 0 3rem;
}
