.job-description {
    color: #000;
    text-decoration: none;
}

.job-description:hover {
    color: #000; 
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    /* transition: color 0.3s ease; */
    cursor: pointer;
    /* transform: translateY(-0.5px); */
    font-weight: 600;
    /* transition: all 0.5s ease-in-out; */
  
}

.apply-now{
    color: #000;
    text-Decoration: none;
    
}

.apply-now:hover{
    color: #000; 
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    /* transition: color 0.3s ease; */
    cursor: pointer;
    /* transform: translateY(-0.5px); */
    font-weight: 600;
    /* transition: all 0.5s ease-in-out; */
}
