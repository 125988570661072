.left_side{
    flex : 3;
    padding-right: 6rem;
}


@media (max-width: 768px){
    .left_side{
        padding-right: 0;
        
    }
    .banner_img-risk{
        height: 100vh;
    }
    .right_side{
        display: none;
    }
}