/****** Half Screen ******/
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  /****** Hero *****/
  .turning_globe {
    right: 25%;
    top: 13%;
  }
  .video_section1 {
    width: 130vw;
  }

  /****** News *****/

  .news_img_container {
    height: 40vh !important;
  }
  .vfds_banner-width {
    width: 50% !important;
  }
}

/****** Tablet Screen ******/
@media screen and (min-width: 600px) and (max-width: 1024px) {
  /****** Menu *****/
  .nav_logo {
    width: 20% !important;
  }
  nav .nav_menu ul {
    gap: 2.5rem !important;
  }
  nav .nav_menu ul li a {
    font-size: 15px !important;
  }

  /****** HERO ******/
  .turning_globe {
    top: 20% !important;
    right: 40% !important;
  }

  .hero-container__content {
    top: 5%;
  }
  .turning_globe video {
    width: 130vw !important;
  }

  .hero-container__content {
    margin-top: 5rem;
  }

  /***** SOLUTIONS *****/
  .solution_container {
    align-items: center !important;
    justify-content: center !important;
  }
  .solution_container .content_title {
    font-size: 3rem !important;
    text-align: center;
    margin-bottom: 1rem;
  }

  /***** INDUSTRIES *****/
  .ind_title {
    font-size: 3rem;
    padding-right: 4rem;
  }

  .industry_tech {
    justify-content: start !important;
    align-items: start !important;
  }

  .industry_tech-title {
    font-size: 2.5rem !important;
  }

  .industry_tech-list {
    gap: 1rem !important;
  }

  /**** NEWS ****/

  .news__title img {
    width: 30% !important;
  }

  .news__title span {
    font-size: 3.5rem !important;
  }
  .news__title-sub {
    font-size: 2.5rem !important;
  }
  .container_news {
    padding-left: 0;
  }

  /***** VFDS ****/
  .vfds_card {
    width: 100% !important;
    height: 100% !important;
    text-align: center !important;
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .service_img,
  .service-text_content {
    width: 100% !important;
  }

  .service-text_content {
    text-align: center !important;
    align-items: center !important;
  }

  .text_content.vfdsClass {
    display: flex;
    height: 100% !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
  }

  .request-demo-form .form-group {
    width: 100% !important;
    padding: 0 !important;
  }
  .request-demo-form .submit-btn {
    width: 100% !important;
  }

  .compliance_box {
    width: 100% !important;
  }

  .vfds_banner-width {
    width: 50% !important;
  }

  /******************************/
  /********* INDUSTRIES *********/
  /******************************/
  .industrie_page-title img {
    width: 25% !important;
  }

  /* .section_text-content h4{
        font-size: 3rem!important;
    } */

  .card_sections .section_text-content h4 {
    font-size: 2.5rem !important;
  }

  .card_sections .section_text-content p {
    font-size: 1.3rem;
    top: 20% !important;
  }
  .card_section-container {
    max-width: 100% !important;
  }

  .vtech_container .slick-next {
    right: -3rem !important;
    transform: scale(1.9);
  }

  .vtech_container .slick-prev {
    left: -2rem !important;
    transform: scale(1.9);
  }

  /* .card_sections .section_text-content {
        align-items: center;
        padding: 20px;
    } */

  .card_sections .section_text-content {
    align-items: flex-start;
    padding: 20px;
  }

  .card_sections {
    height: 47rem !important;
    width: 100% !important;
  }

  .section_text-content {
    height: 62vh !important;
  }

  .section_banner {
    height: 30vh !important;
  }
  nav .nav_search {
    display: none !important;
  }
  .vfds_banner-width {
    width: 50% !important;
  }
  .vfds_top-banner_width {
    width: 25% !important;
  }

  /***** Overview *****/
  .overviewClass {
    text-align: center !important;
    width: 50% !important;
    left: 0 !important;
    top: 2rem;
  }
  footer .footer_siteMap {
    align-items: center !important;
    text-align: center !important;
  }
  .part2_ceo p {
    text-align: center !important;
    width: 100% !important;
    padding: 0 !important;
  }

  .part1_ceo p {
    text-align: center !important;
    padding: 0 !important;
  }
}

/****** Mobile Screen ******/
@media screen and (max-width: 600px) {
  .nav_logo {
    width: 40% !important;
  }
  /****** Hero *****/

  nav {
    width: 100vw !important;
  }

  .hero-container__content {
    top: 5%;
  }
  .hero-container div.container h1 {
    font-size: 35px !important;
    width: 100% !important;
    line-height: 2.5rem !important;
    text-align: center !important;
    top: 20% !important;
  }
  .hero-container__content h2 {
    width: 100% !important;
    line-height: 2rem !important;
    text-align: center !important;
  }
  .turning_globe {
    top: 25% !important;
    right: 23% !important;
  }
  .video_section1 {
    width: 200vw !important;
  }

  /***** SOLUTIONS *****/
  .solution_container {
    align-items: center !important;
    justify-content: center !important;
  }
  .solution_container .content_title {
    font-size: 2rem !important;
    text-align: center;
    margin-bottom: 1rem;
  }

  /****** News *****/

  .news_img_container {
    height: 40vh !important;
  }

  .news__title img {
    width: 60% !important;
  }

  /***** Counter *****/
  .inner_container {
    flex-direction: column !important;
  }

  /**** Vfds *****/
  .vfds_card {
    width: 100% !important;
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .service-text_content {
    align-items: center !important;
  }

  .service_img,
  .service-text_content {
    width: 100% !important;
    text-align: center !important;
  }
  nav .nav_search {
    display: none !important;
  }
  .intro_component {
    text-align: center;
  }
  .compliance_box {
    width: 100% !important;
  }
  .text_content.vfdsClass {
    left: 8% !important;
    height: 100% !important;
    width: auto;
    top: 40% !important;
    text-align: center !important;
  }
  .form-title p {
    font-size: 1rem !important;
  }
  .request-demo-form .form-group {
    width: 100% !important;
    padding: 0 !important;
  }
  .request-demo-form .submit-btn {
    width: 100% !important;
  }
  .vfds_banner-width {
    width: 75% !important;
  }

  /**** solutions ****/

  .solution_sections {
    padding: 0 0.5rem !important;
  }

  /**** Industries ****/

  .industry_bigcontainer {
    padding: 0.25rem !important;
  }

  .card_sections {
    width: 100% !important;
    height: 45rem !important;
  }

  .card_section-container {
    padding: 0 !important;
  }

  /**** Request Demo ****/
  .request-demo-form {
    max-width: 100% !important;
  }

  /**** Technology ****/

  .vtech_container .slick-slide {
    padding: 1.5rem !important;
  }

  .vtech_container .slick-next {
    right: 30% !important;
    transform: scale(1.9) !important;
    top: 33rem !important;
  }

  .vtech_container .slick-prev {
    left: 30% !important;
    transform: scale(1.9) !important;
    top: 33rem !important;
  }

  .industryPage .slick-next {
    right: 30% !important;
    transform: scale(1.9) !important;
    top: 36rem !important;
  }

  .industryPage .slick-prev {
    left: 30% !important;
    transform: scale(1.9) !important;
    top: 36rem !important;
  }
  .industryPage .section-slide {
    height: 35rem !important;
  }

  .consultancyClass {
    text-align: center !important;
  }

  .consultancyClass h1,
  .text_content h1 {
    line-height: 4rem !important;
  }

  .footer_logo {
    width: 75% !important;
  }

  footer .footer_siteMap,
  .footer_siteMap div {
    align-items: center !important;
  }

  .footer_siteMap ul {
    text-align: center !important;
  }
}
