.vtech_container .section-slide {
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.vtech_container .slick-slide:hover {
  transform: none;
}

.vtech_container .section-slide.active {
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

.vtech_container .section-slide.deactivate {
  opacity: 0.5;
  transform: scale(0.8);
}

.vtech_container :is(.slick-next, .slick-prev) {
  transform: scale(1.5);
}
