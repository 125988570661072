.pressRelease_overlay {
  width: 100%;
  height: 650px;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
}

.pressRelease_container .pressRelease_content {
  width: 60%;
}

.pressRelease_container h1 {
  position: relative;
  z-index: 2;
  text-shadow: 1px 5px #051c2c;
  font-size: 2.5rem;
  line-height: 3.5rem !important;
}

.line-2 {
  line-height: 2rem;
}

.pressRelease_container-content h5 {
  line-height: 2rem;
}

@media screen and (max-width: 768px) {
  
  .pressRelease_container h1 {
    position: relative;
    z-index: 2;
    text-shadow: 1px 5px #051c2c;
    font-size: 1.5rem;
    line-height: 2rem !important;
  }
}