.hero-container {
    margin-top: -5rem;
    background-color: #051c2c;
    height: calc(100vh + 5rem);
    z-index: 1;
}

.hero-container .container {
    max-width: 100vw;
    padding: 0 4rem;
}

.hero-container__content h2{
    width: 50%;
}

.hero-container .overlay{
    position: absolute;
    top: 0;
    z-index: 2;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.hero-container div.container {
    height: calc(100vh + 5rem);
}

.hero-container__content{
    margin-top: 3rem;
    position: relative;
    z-index: 3;
}

.hero-container div.container h1{
    font-size: 55px;
    line-height: 4.5rem;
    font-family: var(--font-poppins);
}

.hero-container div.container h2{
    line-height: 3rem;
    font-family: var(--font-poppins);
}

.video_section1{
    width: 100vw;
}

.turning_globe {
    z-index: -1;
    right: 37%;
    top: 1%;
}
