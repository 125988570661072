.insight_img {
  height: 10rem;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.btn-insights {
  color: var(--primary-bg);
  background-color: #fff;
  border-radius: 0;
  padding: 0.6rem;
}

.btn-insights:hover {
  color: #fff;
  border: 1px solid #fff;
  background-color: var(--primary-bg);
}

.date_container {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  color: #fff;
  border-radius: 8px;
  top: 10%;
  left: 10%;
}
