.vfds_container span {
  margin: 4rem 0;
}

.vfds_container .h-header {
  height: 4rem;
}

.vfds_container span {
  width: 2rem;
  height: 0.2rem;
  background-color: #001f3d;
}

.vfds_container h4 {
  font-family: var(--font-poppins);
  color: #001f3d !important;
  font-weight: 400;
}

.vfds_cards .vfds_card .vfds_card-img {
  height: 12rem;
  background-position: center center;
  background-size: cover;
  border-radius: 15px 15px 0 0;
  background-repeat: no-repeat;
}

.vfds_card {
  background-color: #000 !important;
  position: relative;
  z-index: 10;
  width: 30%;
  height: 25rem;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
}

.vfds_cards .vfds_card .overlayEffect {
  position: absolute;
  top: 8%;
  width: 100%;
  height: 23rem;
  background-color: #e0e0e0;
  z-index: -1;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
}

.vfds_cards .vfds_card:hover .overlayEffect {
  background-color: #001f3d;
  transition: all 0.3s ease-in-out;
}

.vfds_cards .text-content {
  position: relative;
  background: #fff;
  height: 12.5rem;
}
