.img_content-healthcare{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.img_content-healthcare {
    .text_content{
        h1 {
            font-size: 3rem;
            line-height: 3.5rem;
            margin-bottom: 2rem;
            font-weight: bold;
        }
        h4 {
            font-size: 2.5vw;
            line-height: 2.2rem;
        }
    } 
}