/* .submenu-section {
  width: 47%;
} */
.submenu-section,
.about-us-submenu p,
.submenu-section li,
.submenu-section li a {
  color: #051c2c !important;
}

.submenu-section li a:hover {
  border-bottom: 1px solid;
}

.submenu-section li a {
  font-size: 0.8rem !important;
}

nav.active .about-us-submenu {
  opacity: 1;
}

.about-us-submenu {
  background-color: #fff;
  opacity: 0.9 !important;
}

.about-us-submenu.scrolledSub {
  background-color: #fff;
  box-shadow: 2px 2px 6px #051c2c;
  opacity: 1 !important;
}

.text-blue {
  color: #051c2c !important;
}
