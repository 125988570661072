.img_content-gov{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.img_content-gov {
    .text_content{
        h1 {
            font-size: 3rem;
            line-height: 3.5rem;
            margin-bottom: 2rem;
            font-weight: bold;
        }
        h4 {
            font-size: 2.5vw;
            line-height: 2.2rem;
        }
    } 
}

.overlay_bg{
    position: absolute;
    top: 0;
    height: 99%;
    width: 100%;
    background: rgba(0,0,0,0.15);
    z-index: 0;
}