.border-seperator{
    border-right: 1px solid;
    padding-right: 0.8rem;
    height: 9vh;
    display: flex;
    align-items: center;
}

.tech_list-item svg {
    transform: translateX(0);
    transition: all 0.3s ease-in-out;
}

.tech_list-item:hover svg {
    transform: translateX(10px);
    transition: all 0.3s ease-in-out;
}

.ind_title{
    font-size: 2rem;
}

.industry_tech{
    justify-content: center;
    align-items: center;
}

.industry_tech-list{
    gap: 0rem;
}

.industry_tech-title{
    font-size: 3rem;
}

.tech_list-item{
    color: var(--bs-heading-color)
}