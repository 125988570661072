
@mixin animation($str) {
-webkit-animation: #{$str};
-moz-animation: #{$str};
-ms-animation: #{$str};
-o-animation: #{$str};
animation: #{$str};      
}

body {
    background-color: rgb(244, 244, 244);
}
  
  .logo_banner-bg{
    // background: linear-gradient(to bottom , rgba(110, 0, 110, 0.719), #975ba8);
    height: 25rem;
    background-position: center center;
    background-size: cover;

    .outer-img{
        position: absolute;
        height: 25rem;
        clip-path: polygon(72% 0, 100% 0%, 100% 100%, 65% 100%);
        opacity: 0.5;
        left: 1%;
    }

    .logo_shift{
        height: 8rem;
        overflow-y: hidden;
        position: absolute;
        top: 45%;
        left: 1%;
        img{
            width: 25%;
            padding-bottom: 0.5rem;
        }
        h1{
            margin: 0;
            font-size: 5.3rem;
            padding: 0;
            color: #fff;
            font-weight: 600;
            font-family: 'Poppins', sans-serif;
            &.animateFlip{
                animation: rotateUp 7s infinite ease-in-out;
            }

            @keyframes rotateUp{
                0%{
                    transform: translateY(100px);
                }
                12.5%{
                    transform: translateY(100px);
                }
                25%{
                    transform: translateY(0px);
                }
                37.5%{
                    transform: translateY(0px);
                }
                50%{
                    transform: translateY(0px);
                }
                62.5%{
                    transform: translateY(-105px);
                }
                75%{
                    transform: translateY(-105px);
                }
                87.5%{
                    transform: translateY(-105px);
                }
                100%{
                    transform: translateY(100px);
                }
            }
        }
    }
}
